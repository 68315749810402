import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { SubNav } from '.'

export const NavItems = ({ LinkElement, navItems = [], ...props }) => {
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  const linkElementParams = ({ href, newTab = true }) => {
    if (href) {
      return {
        as: LinkElement || 'a',
        [LinkElement ? 'to' : 'href']: href,
        target: newTab ? '_blank' : '_self',
      }
    }
  }

  return (
    <Stack {...props}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                {...linkElementParams(navItem)}
                p={2}
                fontSize={14}
                color={'gray.500'}
                cursor={'pointer'}
                _hover={{
                  color: 'primary.500',
                }}
                display={'flex'}
                alignItems={'center'}
              >
                {navItem.label}

                {navItem.children && <ChevronDownIcon fontSize={24} />}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <SubNav
                      LinkElement={LinkElement}
                      key={child.label}
                      {...child}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}
