import {
  DocsPriceFeed,
  DocsPriceFeedOverview,
  DocsVrf,
  DocsVrfDirectFunding,
  DocsVrfOverview,
  DocsVrfSubscription,
} from '@/components/organisms'
import { DocsPriceFeedContractAddresses } from '@/components/organisms/Docs/price-feed/contractAddresses'
import { DocsPriceFeedDeveloperResponsibilities } from '@/components/organisms/Docs/price-feed/developerResponsibilities'
import { DocsPriceFeedGettingStarted } from '@/components/organisms/Docs/price-feed/gettingStarted'
import { DocsPriceFeedUsingPriceFeeds } from '@/components/organisms/Docs/price-feed/usingPriceFeeds/DocsPriceFeedUsingPriceFeeds'
import { Docs } from '@/components/pages'

const docsRoutes = {
  path: 'docs',
  element: <Docs />,
  children: [
    {
      path: 'price-feed',
      element: <DocsPriceFeed />,
      children: [
        {
          path: 'overview',
          element: <DocsPriceFeedOverview />,
        },
        {
          path: 'getting-started',
          element: <DocsPriceFeedGettingStarted />,
        },
        {
          path: 'developer-responsibilities',
          element: <DocsPriceFeedDeveloperResponsibilities />,
        },
        {
          path: 'contract-addresses',
          element: <DocsPriceFeedContractAddresses />,
        },
        {
          path: 'using-price-feeds',
          element: <DocsPriceFeedUsingPriceFeeds />,
        },
      ],
    },
    {
      path: 'vrf',
      element: <DocsVrf />,
      children: [
        {
          path: 'overview',
          element: <DocsVrfOverview />,
        },
        {
          path: 'subscription',
          element: <DocsVrfSubscription />,
        },
        {
          path: 'direct-funding',
          element: <DocsVrfDirectFunding />,
        },
      ],
    },
  ],
}

export default docsRoutes
