// import { bahamut, ocean, supportedChainsMapping } from '@/'
import { SubCalculator } from '@/components/molecules'
import { Box, Text } from '@chakra-ui/react'

export const DocsVrfSubscriptionBilling = () => {
  return (
    <Box>
      <Text fontSize={['16px', '18px']} fontWeight={300}>
        When it comes to generating random numbers, there are three fees that
        you should be aware of. The first fee is the gas fee required for the
        consumer to initiate a random number request. The second fee is a
        service fee charged by Erinaceus Oracles for the work of random number
        generation. The third fee is a gas fee required to fulfill the request.
        The gas price depends on current network conditions. The callback gas
        depends on your callback function, and the number of random values in
        your request.
      </Text>
      <Text
        mt={'20px'}
        textAlign={'center'}
        fontSize={['16px', '18px']}
        fontWeight={400}
      >
        (Gas price * (Verification gas + Callback gas)) = total gas cost
      </Text>
      <Text textAlign={'center'} fontSize={['16px', '18px']} fontWeight={400}>
        (total gas cost + service fee) = total request cost
      </Text>

      <SubCalculator mt={'20px'} />
    </Box>
  )
}
