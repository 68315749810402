import { Box, Flex, HStack, Heading, Image, Text } from '@chakra-ui/react'
import { MainContainer } from '@erinaceus/ui'

const features = [
  {
    title: 'Unpredictability',
    text: "Both the block hash (included in seed data) and the Oracle's secret key are used to generate randomness, guaranteeing the unpredictable nature of Erinaceus VRF.",
    image: '/images/features/feature-1.png',
    color: 'tertiary.50',
  },
  {
    title: 'Verifiability',
    text: 'Anyone can verify the proof using the public key corresponding to the secret key.',
    image: '/images/features/feature-2.png',
    color: 'alcohol.50',
  },
  {
    title: 'Randomness',
    text: "It's cryptographically infeasible to find two seeds with same VRF output from a fixed, valid VRF key",
    image: '/images/features/feature-3.png',
    color: 'gray.50',
  },
]

export const OracleFeatures = ({ ...props }) => {
  return (
    <Box {...props}>
      <MainContainer>
        <HStack
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'stretch', lg: 'center' }}
          spacing={{ base: '20px', lg: '36px', xl: '56px' }}
        >
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'stretch', md: 'center' }}
            gap={{ base: '20px', lg: '36px', xl: '56px' }}
          >
            <Image
              alignSelf={'flex-start'}
              maxW={{ base: '200px', sm: '220px', md: '240px', xl: '280px' }}
              src={'/images/oracle-features.png'}
            />

            <Box>
              <Heading
                lineHeight={['normal', '60px']}
                color={'gray.900'}
                fontSize={{ base: '36px', lg: '48px', xl: '56px' }}
                fontWeight={600}
              >
                You are only
                <br />
                as{' '}
                <Text as="span" textDecoration={'underline'}>
                  decentralized
                </Text>{' '}
                as your data
              </Heading>
            </Box>
          </Flex>
          <Box maxW={{ base: '100%', lg: '315px' }}>
            <Text fontSize={['18px', '20px']} fontWeight={300}>
              Blockchains cannot natively access off-chain data (like API feeds
              or transaction data from another chain), so web3 projects have
              relied on their own methods of sourcing real-world data for their
              smart contracts.
            </Text>
          </Box>
        </HStack>

        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          mt={'70px'}
          gap={'24px'}
          alignItems={'stretch'}
        >
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </Flex>
      </MainContainer>
    </Box>
  )
}

export const Feature = ({ title, text, color, image }) => {
  return (
    <Box
      borderRadius={'16px'}
      flex={1}
      bgColor={color}
      p={{ base: '16px', lg: '32px' }}
    >
      <Image
        mb={{ base: '0', sm: '16px', lg: '24px' }}
        w={148}
        h={148}
        src={image}
      />
      <Heading
        mb={'12px'}
        fontSize={{ base: '22px', md: '24px' }}
        fontWeight={400}
      >
        {title}
      </Heading>
      <Text fontSize={'16px'} fontWeight={300}>
        {text}
      </Text>
    </Box>
  )
}
