import { DocsBody, DocsVrfOverviewConfigs } from '@/components/organisms'
import { Box, VStack } from '@chakra-ui/react'

const QuestionOnEqualSymbol = () => (
  <VStack position={'relative'}>
    <Box fontSize={'11px'} top={'-2px'} position={'absolute'}>
      ?
    </Box>
    <Box>=</Box>
  </VStack>
)

export const DocsVrfOverview = () => {
  const docs = [
    {
      label: 'Main',
      children: [
        {
          text: 'Erinaceus VRF is a Verifiable Random Function (VRF) solution that enables blockchain developers to generate random numbers.',
        },
        {
          title: 'The Challenge of Generating Randomness',
          text: 'When generating randomness, a good output must fulfill four criteria: unbiased, unpredictable, verifiable, and instantly available.',
        },
        {
          text: 'Many blockchain developers find it challenging to produce on-chain randomness in their applications due to the deterministic nature of the blockchain. The on-chain generated randomness usually can’t satisfy the unpredictability.',
        },
        {
          text: 'On the other hand, purely relying on a simple off-chain oracle provider could force developers to compromise on availability or, even worse, security. In certain contexts, if a malicious provider feeds some predictable randomness, bad actors can exploit the situation to ensure they receive a favourable result. Imagine a loot-based game where players could try to open a treasure chest multiple times until they get an item they’re happy with. Or a card game where players could draw their hands numerous times until they get a good one.',
        },
        {
          title: 'What’s Under the Hood',
          text: 'VRF combines these two independent sources for seed generation. The randomness constructed by the off-chain provider with its private key is generated from the two parameters block-hash and preSeed. This ensures the unpredictability of the proof, as you cannot predict the block hash until the block is generated.',
        },
        {
          text: 'The proof provided off-chain ensures that the randomness is generated from block-hash and preSeed rather than something that a malicious provider could make up. That makes this randomness verifiable.',
        },
        {
          text: 'In simple terms, developers can generate random numbers fairly and securely.',
        },
        {
          text: 'Erinaceus VRF can be used for an extensive selection of use cases, including GameFi projects, NFT markets, Random entity selection and more.',
        },
      ],
    },
    {
      label: 'Structure',
      children: [
        {
          title: 'Erinaceus VRF solution consists of 2 components.',
          text: 'On-chain component: A contract(RNG Manager contract) designed to interact with the provider service. It emits an event when a request for randomness is made, and then verifies the random number and proof of how it was generated by the service.',
        },
        {
          text: 'Off-chain component: Provider service listens for requests by subscribing to the RNG Manager contract event logs and calculates a random number based on the block hash and nonce. Then sends a transaction to the RNG Manager including the random number and a proof of how it was generated.',
        },
      ],
    },
    {
      label: 'Methods',
      children: [
        {
          title:
            'There are 2 main methods to pay for the RNG solution: subscription and direct funding',
          text: 'Create a subscription account and fund its balance with FTN tokens. Users can then connect multiple consuming contracts to the subscription account. When the consuming contracts request randomness, the transaction costs are calculated after the randomness requests are fulfilled and the subscription balance is deducted accordingly.  After the request is complete, the final gas cost is recorded based on how much gas is used for the verification and callback. The actual cost of the request is deducted from your subscription balance.',
        },
        {
          text: 'Consuming contracts directly pay with FTN when they request random values. You must directly fund your consumer contracts and ensure that there are enough FTN tokens to pay for randomness requests. Works best when end-users must pay the fees for RNG because the cost of the request is determined at request time. Because the consuming contract directly pays the FTN for the request, the cost is calculated during the request and not during the callback when the randomness is fulfilled.',
        },
      ],
    },
    {
      label: 'Security',
      children: [
        {
          title: 'Unpredictability',
          text: 'Randomness has to be unpredictable to ensure that it cannot be manipulated or influenced in any way. If a random number generator (RNG) is predictable, it can be exploited by attackers to gain an advantage in various scenarios, such as blockchain gaming. To ensure Erinaceus is generating unpredictable randomness, we rely on the two unpredictable key factors:',
        },
        {
          subtitle: 'Secret key from the oracle',
          text: 'Erinaceus adopted a public key version of a keyed cryptographic hash algorithm described by Goldberg in this paper for VRF. A secret key is used in this algorithm which is only known to the oracle. The hashing function looks like this',
        },
        {
          code: 'R=hashVRF​(SK,S)',
        },
        {
          text: "where SK is the secret key of the oracle, S is the input to the VRF algorithm consisting of the consumer's input and block hash and R is the randomness.",
        },
        {
          subtitle: 'Block hash',
          text: "The block-hash is unknown before the block is packaged and confirmed by the miners, and can only be determined after the block is generated. That implies even a malicious oracle can't generate the randomness upfront before the block hash is confirmed. The block hash is included in the S as an input string to the VRF algorithm.",
        },
        {
          text: 'Both the block hash (included in S) and the secret key SK are used to generate randomness, guaranteeing the unpredictable nature of Erinaceus VRF.',
        },
        {
          title: 'Verifiability',
          text: 'It is important for randomness to be verifiable so that users can ensure that the outcome of a random event was not manipulated or biassed in favour of one party. This is particularly important in areas such as gambling or voting, where trust in the integrity of the system is essential. By making the randomness verifiable, users can have confidence that the system is fair and unbiased. This is especially important in decentralized systems, where there is no central authority to ensure fairness.',
        },
        {
          text: 'The randomness generated by Erinaceus can be verified in L1 by using a proof π that comes together with the randomness. And with a verifying function in the smart contract, consumers can ensure the randomness R generated is correct. The verify function looks like this verify',
        },
        {
          code: (
            <>
              VRF(PK,S,π)
              <QuestionOnEqualSymbol />R
            </>
          ),
        },
        {
          text: "where PK is the public key of the oracle, S is the input to the VRF algorithm consisting of the consumer's input and block hash, π is the proof and R is the randomness.",
        },
        {
          title: 'Randomness',
          subtitle: 'Full uniqueness',
          text: 'For any seed and valid VRF public key, there is exactly one VRF output which can be proved to come from that seed, in the sense that the proof will pass verifyVRFProof.',
        },
        {
          subtitle: 'Full collision resistance',
          text: "It's cryptographically infeasible to find two seeds with same VRF output from a fixed, valid VRF key",
        },
        {
          subtitle: 'Full pseudorandomness',
          text: 'Absent the proofs that the VRF outputs are derived from a given seed, the outputs are computationally indistinguishable from randomness.',
        },
      ],
    },
    {
      label: 'Configs',
      Component: <DocsVrfOverviewConfigs />,
    },
  ]

  return <DocsBody title={'VRF'} subtitle={'Overview'} docs={docs} />
}
