import {
  Faq,
  Intro,
  OracleFeatures,
  OracleProblem,
} from '@/components/organisms'
import { Layout } from '@/components/templates'

export const Home = () => {
  return (
    <Layout>
      <Intro />
      <OracleFeatures />
      <Faq />
      <OracleProblem />
    </Layout>
  )
}
