import { Box } from '@chakra-ui/react'

export const DocsContainer = ({ children, ...props }) => {
  return (
    <Box
      p={{ base: '12px', sm: '16px', md: '18px 36px', lg: '24px 48px' }}
      bgColor={'white'}
      overflow={'hidden'}
      {...props}
    >
      {children}
    </Box>
  )
}
