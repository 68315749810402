import { useWeb3 } from './useWeb3'

// TODO: Maybe remove this hook and replace everywhere to {chain: {contracts:{vrf}}} = useWeb3
export const useContractAddresses = () => {
  const {
    chain: { contracts },
  } = useWeb3()

  return {
    vrf: contracts?.vrf?.address,
  }
}
