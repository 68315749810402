import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const DocsVrf = () => {
  const location = useLocation()

  if (location.pathname === '/docs/vrf') {
    return <Navigate to="overview" replace />
  }
  return <Outlet />
}
