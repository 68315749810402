import { FreshErinaceusIcon } from '../icons'

export const FreshMainLogo = ({ redirect, ...props }) => {
  return (
    <FreshErinaceusIcon
      h={['18px', '28px', '37px']}
      color={'primary.500'}
      cursor={'pointer'}
      w={'auto'}
      {...props}
    />
  )
}
