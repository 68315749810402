import { vrfAbi } from '@/abi'
import { useContractAddresses } from '@/hooks'
import { useReadContract } from 'wagmi'

export const useGetConfig = () => {
  const contracts = useContractAddresses()

  const { data: config, isLoading } = useReadContract({
    abi: vrfAbi,
    address: contracts.vrf,
    functionName: 'getConfig',
  })

  const [, maxGasLimit] = config || []

  return {
    data: maxGasLimit || 0,
    isLoading,
  }
}
