import { Home, Page404 } from '@/components/pages'
import {
  RouterProvider as ReactRouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import docsRoutes from './docsRoutes'
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  docsRoutes,
  {
    path: '*',
    element: <Page404 />,
  },
])

const RouterProvider = ({ children }) => {
  return <ReactRouterProvider router={router}>{children}</ReactRouterProvider>
}

export default RouterProvider
