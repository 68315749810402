import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { MainContainer } from '@erinaceus/ui'

export const OracleProblem = ({ ...props }) => {
  return (
    <Box bgColor={'gray.900'} {...props}>
      <MainContainer>
        <HStack
          justifyContent={'flex-start'}
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          spacing={{ base: '40px', md: '30px', xl: '130px' }}
        >
          <Box>
            <Image
              maxW={{ base: '300px', md: '350px', lg: '400px', xl: '500px' }}
              src={'/images/oracle-problem.svg'}
            />
          </Box>
          <Stack spacing={'40px'} maxW={'600px'}>
            <Heading
              lineHeight={['56px', '60px']}
              fontWeight={600}
              fontSize={['48px', '56px']}
              color={'white'}
            >
              Oracle Problem
            </Heading>
            <Text
              fontSize={['16px', '18px']}
              lineHeight={'26px'}
              fontWeight={300}
              color={'white'}
            >
              The oracle problem revolves around a very simple
              limitation—blockchains cannot pull in data from or push data out
              to any external system as built-in functionality. As such,
              blockchains are isolated networks, akin to a computer with no
              Internet connection.
            </Text>

            <Button
              alignSelf={'flex-start'}
              variant={'solid'}
              as={Link}
              target="_blank"
              href={'https://encyclopedia.pub/entry/2959'}
              rightIcon={<ArrowForwardIcon />}
            >
              Learn More
            </Button>
          </Stack>
        </HStack>
      </MainContainer>
    </Box>
  )
}
