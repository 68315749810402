import { Box, Button, HStack, Image, Text, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import { CodeBlock } from 'react-code-blocks'

const code = `pragma solidity ^0.8.6;

import "../vrf/VRFV2WrapperConsumerBase.sol";
import "../vrf/ErinaceusVRFWrapper.sol";
import "hardhat/console.sol";

contract ConsumerWrapperMock is VRFV2WrapperConsumerBase {
    ErinaceusVRFWrapper public erinaceus;
    uint256[] public random;
    mapping(uint256 => uint256) public randomWord;
    uint256 public currentId;
    constructor(address erinaceusVRFWrapper)VRFV2WrapperConsumerBase(erinaceusVRFWrapper){
        erinaceus = ErinaceusVRFWrapper(erinaceusVRFWrapper);
    }
    function getRandom(uint256 id) public view returns (uint256){
        return randomWord[id];
    }

    function getRandom1() public view returns (uint256[] memory){
        return random;
    }
    function generateRandomWords(
        uint16 requestConfirmations,
        uint32 callbackGasLimit,
        uint32 numWords
    ) external payable {
            requestRandomness(
            callbackGasLimit,
            requestConfirmations,
            numWords,
            msg.value
            );
    }
    function fulfillRandomWords(uint256 requestId, uint256[] memory randomWords) internal virtual override {
        // random = randomWords;
        randomWord[currentId] = randomWords[0];
        currentId++;
    }
}
`

const ConsumerCode = ({ ...props }) => {
  const toast = useToast()

  const handleCopy = () => {
    copy(code)
    toast({
      title: `Copied`,
      position: 'bottom',
      duration: 1000,
      isClosable: true,
      status: 'success',
    })
  }

  return (
    <Box {...props}>
      <HStack
        mb={'24px'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <Text
          fontSize={['16px', '18px']}
          wordBreak={'break-word'}
          fontWeight={300}
        >
          contracts/Mocks/ConsumerWrapperMock.sol
        </Text>
        <Button onClick={() => handleCopy()} variant={'outline'}>
          Copy source code
        </Button>
      </HStack>

      <CodeBlock
        as={Box}
        w={500}
        text={code}
        language={'solidity'}
        showLineNumbers={true}
        theme={'dracula'}
      />
    </Box>
  )
}

export const DocsVrfDirectFundingUsingFlow = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <Image
          src="/images/direct-funding-flow.png"
          alt="VRF Direct Funding Flow"
          m={'0 auto'}
        />
        <Text my={'20px'} fontSize={'18px'} fontWeight={300}>
          The consumer contract is a contract prepared by the consumer to
          receive the random numbers. It needs to inherit and override the
          fulfillRandomWords method of the abstract contract
          ErinaceusVRFWrapper.sol.
        </Text>
        <Text fontSize={'18px'} fontWeight={300}>
          A simple consumer contract as follows.
        </Text>
      </Box>

      <ConsumerCode mt={'40px'} />
    </Box>
  )
}
