import { vrfAbi } from '@/abi'
import { useContractAddresses } from '@/hooks'
import { useReadContract } from 'wagmi'

export const useGetFeeConfig = () => {
  const contracts = useContractAddresses()

  const { data: feeConfig, isLoading } = useReadContract({
    abi: vrfAbi,
    address: contracts.vrf,
    functionName: 'getFeeConfig',
  })

  const [protocolFee] = feeConfig || []

  return {
    data: protocolFee || 0,
    isLoading,
  }
}
