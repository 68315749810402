import { SharedChakraProvider } from '@erinaceus/ui'

const theme = {
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
        overflowX: 'hidden',
      },
    },
  },
}

const ChakraProvider = ({ children }) => {
  return <SharedChakraProvider theme={theme}>{children}</SharedChakraProvider>
}

export default ChakraProvider
