import { Box, Text } from '@chakra-ui/react'
import { trimAddress } from '@erinaceus/utils'
import React from 'react'

// eslint-disable-next-line react/display-name
export const Address = React.forwardRef(
  ({ address, symbols = 3, fullAddress = false, ...props }, ref) => {
    return (
      <Box overflow={'hidden'}>
        {fullAddress ? (
          <Text ref={ref} wordBreak={'break-word'} {...props}>
            {address}
          </Text>
        ) : (
          <Text ref={ref} {...props}>
            {trimAddress(address, symbols)}
          </Text>
        )}
      </Box>
    )
  },
)
