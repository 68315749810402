export default function formatWithDecimals(number, decimals) {
  // Convert decimals to a divisor
  const divisor = 10 ** decimals

  // Perform the division
  const result = Number(number) / divisor

  // Format the result to the specified number of decimal places
  return result.toFixed(5)
}
