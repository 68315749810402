import { DocsBody } from '@/components/organisms'
import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import { CodeBlock } from 'react-code-blocks'

const code = `// SPDX-License-Identifier: MIT
  pragma solidity ^0.8.7;
  import {AggregatorV3Interface} from "./interfaces/AggregatorV3Interface.sol";
  /**
   * THIS IS AN EXAMPLE CONTRACT THAT USES HARDCODED
   * VALUES FOR CLARITY.
   * THIS IS AN EXAMPLE CONTRACT THAT USES UN-AUDITED CODE.
   * DO NOT USE THIS CODE IN PRODUCTION.
   */
   contract DataConsumerV3 {
      AggregatorV3Interface internal dataFeed;
      /**
      * Network: Ocean
      * Aggregator: FTN/USD
      * Address: 0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
      */
      constructor() {
          dataFeed = AggregatorV3Interface(
              0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
          );
      }	/**
       * Returns the latest answer.
       */
      function getErinaceusDataFeedLatestAnswer() public view returns (int) {
          // prettier-ignore
          (
              /* uint80 roundID */,
              int answer,
              /*uint startedAt*/,
              /*uint timeStamp*/,
              /*uint80 answeredInRound*/
          ) = dataFeed.latestRoundData();
          return answer;
      }
  }
`

export const DocsPriceFeedGettingStarted = () => {
  const docs = [
    {
      label: 'Consuming Price Feeds',
      children: [
        {
          text: 'Erinaceus Price Feeds are a powerful tool for integrating real-world data, such as asset prices, into your smart contracts. For example, you can use a Price Feed to get the current FTN/USDT exchange rate. These feeds aggregate data from multiple independent sources, ensuring high accuracy and reliability. Each feed has a unique on-chain address and provides functions for accessing the latest data.',
        },
        {
          text: 'To illustrate, consider a DeFi application that requires the latest FTN price in USD. By integrating Erinaceus Price Feeds, your smart contract can directly access this price data on-chain, enabling real-time updates and decision-making based on the most current information.',
        },
        {
          text: 'This guide will walk you through the process of using Erinaceus Price Feeds in your smart contracts and storing the fetched values on-chain with Solidity.',
        },
      ],
    },
    {
      label: 'Examine the sample contract',
      children: [
        {
          text: 'This example contract retrieves the latest price of FTN/USD from the Erinaceus Feed on the Ocean testnet. You can easily adjust it to access any other type of Price Feed available.',
        },
        {
          Component: (
            <CodeBlock
              as={Box}
              w={500}
              text={code}
              language={'solidity'}
              showLineNumbers={true}
              theme={'dracula'}
            />
          ),
        },
        { text: 'The contract has the following components:' },
        {
          Component: (
            <UnorderedList spacing={3}>
              <ListItem>
                The <b>import</b> line imports an interface named{' '}
                <b>AggregatorV3Interface</b>. Interfaces define functions
                without their implementation, which leaves inheriting contracts
                to define the actual implementation themselves. In this case,{' '}
                <b>AggregatorV3Interface</b> defines that all v3 Aggregators
                have the function <b>latestRoundData</b>. You can see the
                complete code for the <b>AggregatorV3Interface</b> on GitHub.
              </ListItem>
              <ListItem>
                The <b>constructor()</b> <b>{'{ }'}</b> initializes an interface
                object named dataFeed that uses AggregatorV3Interface and
                connects specifically to a proxy aggregator contract that is
                already deployed at{' '}
                <b>0x1b44F3514812d835EB1BDB0acB33d3fA3351Ee43</b>. The interface
                allows your contract to run functions on that deployed
                aggregator contract.
              </ListItem>
              <ListItem>
                The <b>getErinaceusDataFeedLatestAnswer()</b> function calls
                your <b>dataFeed</b> object and runs the{' '}
                <b>latestRoundData()</b> function. When you deploy the contract,
                it initializes the <b>dataFeed</b> object to point to the
                aggregator at <b>0x1b44F3514812d835EB1BDB0acB33d3fA3351Ee43</b>,
                which is the proxy address for the Sepolia FTN / USD Price Feed.
                Your contract connects to that address and executes the
                function. The aggregator connects with several oracle nodes and
                aggregates the pricing data from those nodes. The response from
                the aggregator includes several variables, but{' '}
                <b>getErinaceusDataFeedLatestAnswer()</b> returns only the{' '}
                <b>answer</b> variable.
              </ListItem>
            </UnorderedList>
          ),
        },
      ],
    },
  ]

  return (
    <DocsBody title={'Price Feed'} subtitle={'Getting Started'} docs={docs} />
  )
}
