import { IconButton } from '@chakra-ui/react'
import { openWindow } from '@erinaceus/utils'

export const SocialIconButton = ({ icon, to, ...props }) => {
  return (
    <IconButton
      onClick={() => {
        openWindow(to)
      }}
      icon={icon}
      w={'44px'}
      minH={'44px !important'}
      fontSize={'20px'}
      variant={'social'}
      {...props}
    />
  )
}
