import { APPS, getMonorepoAppUrl } from '@erinaceus/utils'

export const navItems = [
  {
    label: 'Products',
    children: [
      {
        product: APPS.VRF,
        label: 'VRF',
        subLabel: 'Verifiable Random Function',
        href: `${getMonorepoAppUrl({ app: APPS.VRF })}/subscriptions`,
      },
      {
        product: APPS.PRICE_FEED,
        label: 'Price Feed',
        subLabel: 'Coin pairs',
        href: `${getMonorepoAppUrl({ app: APPS.PRICE_FEED })}`,
      },
    ],
  },
  {
    label: 'Docs',
    href: `${getMonorepoAppUrl({ app: APPS.WEBSITE })}/docs/vrf`,
    newTab: false,
  },
]
