export const vrfAbi = [
  {
    type: 'constructor',
    stateMutability: 'nonpayable',
    inputs: [
      { type: 'address', name: '_hashHub', internalType: 'address' },
      { type: 'address', name: '_owner', internalType: 'address' },
    ],
  },
  {
    type: 'error',
    name: 'BalanceInvariantViolated',
    inputs: [
      { type: 'uint256', name: 'internalBalance', internalType: 'uint256' },
      { type: 'uint256', name: 'externalBalance', internalType: 'uint256' },
    ],
  },
  {
    type: 'error',
    name: 'BlockhashNotInStore',
    inputs: [{ type: 'uint256', name: 'blocNumber', internalType: 'uint256' }],
  },
  { type: 'error', name: 'CantBeAddressZero', inputs: [] },
  {
    type: 'error',
    name: 'GasLimitTooBig',
    inputs: [
      { type: 'uint32', name: 'have', internalType: 'uint32' },
      { type: 'uint32', name: 'want', internalType: 'uint32' },
    ],
  },
  { type: 'error', name: 'IncorrectCommitment', inputs: [] },
  { type: 'error', name: 'InsufficientBalance', inputs: [] },
  {
    type: 'error',
    name: 'InsufficientGasForConsumer',
    inputs: [
      { type: 'uint256', name: 'have', internalType: 'uint256' },
      { type: 'uint256', name: 'want', internalType: 'uint256' },
    ],
  },
  {
    type: 'error',
    name: 'InvalidBlockhash',
    inputs: [{ type: 'uint256', name: 'blockNum', internalType: 'uint256' }],
  },
  { type: 'error', name: 'InvalidCalldata', inputs: [] },
  {
    type: 'error',
    name: 'InvalidConsumer',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'address', name: 'consumer', internalType: 'address' },
    ],
  },
  {
    type: 'error',
    name: 'InvalidRequestConfirmations',
    inputs: [
      { type: 'uint16', name: 'have', internalType: 'uint16' },
      { type: 'uint16', name: 'min', internalType: 'uint16' },
      { type: 'uint16', name: 'max', internalType: 'uint16' },
    ],
  },
  { type: 'error', name: 'InvalidSubscription', inputs: [] },
  {
    type: 'error',
    name: 'MustBeRequestedOwner',
    inputs: [
      { type: 'address', name: 'proposedOwner', internalType: 'address' },
    ],
  },
  {
    type: 'error',
    name: 'MustBeSubOwner',
    inputs: [{ type: 'address', name: 'owner', internalType: 'address' }],
  },
  { type: 'error', name: 'NoCorrespondingRequest', inputs: [] },
  {
    type: 'error',
    name: 'NoSuchProvingKey',
    inputs: [{ type: 'bytes32', name: 'keyHash', internalType: 'bytes32' }],
  },
  {
    type: 'error',
    name: 'NumWordsTooBig',
    inputs: [
      { type: 'uint32', name: 'have', internalType: 'uint32' },
      { type: 'uint32', name: 'want', internalType: 'uint32' },
    ],
  },
  { type: 'error', name: 'OnlyCallableFromLink', inputs: [] },
  { type: 'error', name: 'PaymentTooLarge', inputs: [] },
  { type: 'error', name: 'PendingRequestExists', inputs: [] },
  { type: 'error', name: 'PercentageIsNotInRange', inputs: [] },
  {
    type: 'error',
    name: 'ProvingKeyAlreadyRegistered',
    inputs: [{ type: 'bytes32', name: 'keyHash', internalType: 'bytes32' }],
  },
  { type: 'error', name: 'Reentrant', inputs: [] },
  { type: 'error', name: 'TooManyConsumers', inputs: [] },
  {
    type: 'event',
    name: 'ConfigSet',
    inputs: [
      {
        type: 'uint16',
        name: 'minimumRequestConfirmations',
        internalType: 'uint16',
        indexed: false,
      },
      {
        type: 'uint32',
        name: 'maxGasLimit',
        internalType: 'uint32',
        indexed: false,
      },
      {
        type: 'uint32',
        name: 'gasAfterPaymentCalculation',
        internalType: 'uint32',
        indexed: false,
      },
      {
        type: 'tuple',
        name: 'feeConfig',
        internalType: 'struct ErinaceusVRF.FeeConfig',
        indexed: false,
        components: [
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier1',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier2',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier3',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier4',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier5',
            internalType: 'uint32',
          },
          { type: 'uint24', name: 'reqsForTier2', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier3', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier4', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier5', internalType: 'uint24' },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'FundsRecovered',
    inputs: [
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'HashHubChanged',
    inputs: [
      {
        type: 'address',
        name: 'oldHashHub',
        internalType: 'address',
        indexed: false,
      },
      {
        type: 'address',
        name: 'newHashHub',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'HashHubFunded',
    inputs: [
      {
        type: 'uint256',
        name: 'oldBalance',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'uint256',
        name: 'newBalance',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'OwnershipTransferred',
    inputs: [
      {
        type: 'address',
        name: 'previousOwner',
        internalType: 'address',
        indexed: true,
      },
      {
        type: 'address',
        name: 'newOwner',
        internalType: 'address',
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ProvingKeyDeregistered',
    inputs: [
      {
        type: 'bytes32',
        name: 'keyHash',
        internalType: 'bytes32',
        indexed: false,
      },
      {
        type: 'address',
        name: 'oracle',
        internalType: 'address',
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'ProvingKeyRegistered',
    inputs: [
      {
        type: 'bytes32',
        name: 'keyHash',
        internalType: 'bytes32',
        indexed: false,
      },
      {
        type: 'address',
        name: 'oracle',
        internalType: 'address',
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RandomWordsFulfilled',
    inputs: [
      {
        type: 'uint256',
        name: 'requestId',
        internalType: 'uint256',
        indexed: true,
      },
      {
        type: 'uint256',
        name: 'outputSeed',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'uint256',
        name: 'payment',
        internalType: 'uint256',
        indexed: false,
      },
      { type: 'bool', name: 'success', internalType: 'bool', indexed: false },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RandomWordsRequested',
    inputs: [
      {
        type: 'bytes32',
        name: 'keyHash',
        internalType: 'bytes32',
        indexed: true,
      },
      {
        type: 'uint256',
        name: 'requestId',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'uint256',
        name: 'preSeed',
        internalType: 'uint256',
        indexed: false,
      },
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'uint16',
        name: 'minimumRequestConfirmations',
        internalType: 'uint16',
        indexed: false,
      },
      {
        type: 'uint32',
        name: 'callbackGasLimit',
        internalType: 'uint32',
        indexed: false,
      },
      {
        type: 'uint32',
        name: 'numWords',
        internalType: 'uint32',
        indexed: false,
      },
      {
        type: 'address',
        name: 'sender',
        internalType: 'address',
        indexed: true,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'RewardSet',
    inputs: [
      {
        type: 'uint256',
        name: 'reward',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionCanceled',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionConsumerAdded',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'address',
        name: 'consumer',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionConsumerRemoved',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'address',
        name: 'consumer',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionCreated',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'address',
        name: 'owner',
        internalType: 'address',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionFunded',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'uint256',
        name: 'oldBalance',
        internalType: 'uint256',
        indexed: false,
      },
      {
        type: 'uint256',
        name: 'newBalance',
        internalType: 'uint256',
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionOwnerTransferRequested',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'address',
        name: 'from',
        internalType: 'address',
        indexed: false,
      },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'SubscriptionOwnerTransferred',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64', indexed: true },
      {
        type: 'address',
        name: 'from',
        internalType: 'address',
        indexed: false,
      },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
    ],
    anonymous: false,
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'contract IHashHub' }],
    name: 'HashHub',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'HashHubBalance',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'HashHubReward',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint16', name: '', internalType: 'uint16' }],
    name: 'MAX_CONSUMERS',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint32', name: '', internalType: 'uint32' }],
    name: 'MAX_NUM_WORDS',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint16', name: '', internalType: 'uint16' }],
    name: 'MAX_REQUEST_CONFIRMATIONS',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'acceptSubscriptionOwnerTransfer',
    inputs: [{ type: 'uint64', name: 'subId', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'addConsumer',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'address', name: 'consumer', internalType: 'address' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'cancelSubscription',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'address', name: 'to', internalType: 'address' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'uint64', name: '', internalType: 'uint64' }],
    name: 'createSubscription',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'deregisterProvingKey',
    inputs: [
      {
        type: 'uint256[2]',
        name: 'publicProvingKey',
        internalType: 'uint256[2]',
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'feePercentage',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'fulfillRandomWords',
    inputs: [
      {
        type: 'tuple',
        name: 'proof',
        internalType: 'struct VRF.Proof',
        components: [
          { type: 'uint256[2]', name: 'pk', internalType: 'uint256[2]' },
          { type: 'uint256[2]', name: 'gamma', internalType: 'uint256[2]' },
          { type: 'uint256', name: 'c', internalType: 'uint256' },
          { type: 'uint256', name: 's', internalType: 'uint256' },
          { type: 'uint256', name: 'seed', internalType: 'uint256' },
          { type: 'address', name: 'uWitness', internalType: 'address' },
          {
            type: 'uint256[2]',
            name: 'cGammaWitness',
            internalType: 'uint256[2]',
          },
          {
            type: 'uint256[2]',
            name: 'sHashWitness',
            internalType: 'uint256[2]',
          },
          { type: 'uint256', name: 'zInv', internalType: 'uint256' },
        ],
      },
      {
        type: 'tuple',
        name: 'rc',
        internalType: 'struct ErinaceusVRF.RequestCommitment',
        components: [
          { type: 'uint64', name: 'blockNum', internalType: 'uint64' },
          { type: 'uint64', name: 'subId', internalType: 'uint64' },
          { type: 'uint32', name: 'callbackGasLimit', internalType: 'uint32' },
          { type: 'uint32', name: 'numWords', internalType: 'uint32' },
          { type: 'address', name: 'sender', internalType: 'address' },
        ],
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'fundHashHub',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'fundSubscribtion',
    inputs: [{ type: 'uint64', name: 'subId', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'bytes32', name: '', internalType: 'bytes32' }],
    name: 'getCommitment',
    inputs: [{ type: 'uint256', name: 'requestId', internalType: 'uint256' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      {
        type: 'uint16',
        name: 'minimumRequestConfirmations',
        internalType: 'uint16',
      },
      { type: 'uint32', name: 'maxGasLimit', internalType: 'uint32' },
      {
        type: 'uint32',
        name: 'gasAfterPaymentCalculation',
        internalType: 'uint32',
      },
    ],
    name: 'getConfig',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint64', name: '', internalType: 'uint64' }],
    name: 'getCurrentSubId',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      {
        type: 'uint32',
        name: 'fulfillmentFlatFeeWFTNPPMTier1',
        internalType: 'uint32',
      },
      {
        type: 'uint32',
        name: 'fulfillmentFlatFeeWFTNPPMTier2',
        internalType: 'uint32',
      },
      {
        type: 'uint32',
        name: 'fulfillmentFlatFeeWFTNPPMTier3',
        internalType: 'uint32',
      },
      {
        type: 'uint32',
        name: 'fulfillmentFlatFeeWFTNPPMTier4',
        internalType: 'uint32',
      },
      {
        type: 'uint32',
        name: 'fulfillmentFlatFeeWFTNPPMTier5',
        internalType: 'uint32',
      },
      { type: 'uint24', name: 'reqsForTier2', internalType: 'uint24' },
      { type: 'uint24', name: 'reqsForTier3', internalType: 'uint24' },
      { type: 'uint24', name: 'reqsForTier4', internalType: 'uint24' },
      { type: 'uint24', name: 'reqsForTier5', internalType: 'uint24' },
    ],
    name: 'getFeeConfig',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint32', name: '', internalType: 'uint32' }],
    name: 'getFeeTier',
    inputs: [{ type: 'uint256', name: 'reqCount', internalType: 'uint256' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'uint16', name: '', internalType: 'uint16' },
      { type: 'uint32', name: '', internalType: 'uint32' },
      { type: 'bytes32[]', name: '', internalType: 'bytes32[]' },
    ],
    name: 'getRequestConfig',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'uint256', name: 'balance', internalType: 'uint256' },
      { type: 'uint256', name: 'reqCount', internalType: 'uint256' },
      { type: 'address', name: 'owner', internalType: 'address' },
      { type: 'address[]', name: 'consumers', internalType: 'address[]' },
    ],
    name: 'getSubscription',
    inputs: [{ type: 'uint64', name: 'subId', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'getTotalBalance',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'getWithdrawableAmount',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'pure',
    outputs: [{ type: 'bytes32', name: '', internalType: 'bytes32' }],
    name: 'hashOfKey',
    inputs: [
      { type: 'uint256[2]', name: 'publicKey', internalType: 'uint256[2]' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'oracleWithdraw',
    inputs: [
      { type: 'address', name: 'recipient', internalType: 'address' },
      { type: 'uint96', name: 'amount', internalType: 'uint96' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'address' }],
    name: 'owner',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'ownerCancelSubscription',
    inputs: [{ type: 'uint64', name: 'subId', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'bool', name: '', internalType: 'bool' }],
    name: 'pendingRequestExists',
    inputs: [{ type: 'uint64', name: 'subId', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'recoverFunds',
    inputs: [{ type: 'address', name: 'to', internalType: 'address' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'registerProvingKey',
    inputs: [
      { type: 'address', name: 'oracle', internalType: 'address' },
      {
        type: 'uint256[2]',
        name: 'publicProvingKey',
        internalType: 'uint256[2]',
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'removeConsumer',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'address', name: 'consumer', internalType: 'address' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'renounceOwnership',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'requestRandomWords',
    inputs: [
      { type: 'bytes32', name: 'keyHash', internalType: 'bytes32' },
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'uint16', name: 'requestConfirmations', internalType: 'uint16' },
      { type: 'uint32', name: 'callbackGasLimit', internalType: 'uint32' },
      { type: 'uint32', name: 'numWords', internalType: 'uint32' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'requestSubscriptionOwnerTransfer',
    inputs: [
      { type: 'uint64', name: 'subId', internalType: 'uint64' },
      { type: 'address', name: 'newOwner', internalType: 'address' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'address', name: 'owner', internalType: 'address' },
      { type: 'address', name: 'requestedOwner', internalType: 'address' },
    ],
    name: 's_subscriptionConfigs',
    inputs: [{ type: 'uint64', name: '', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'uint256', name: 'balance', internalType: 'uint256' },
      { type: 'uint256', name: 'reqCount', internalType: 'uint256' },
    ],
    name: 's_subscriptions',
    inputs: [{ type: 'uint64', name: '', internalType: 'uint64' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'setConfig',
    inputs: [
      {
        type: 'uint16',
        name: 'minimumRequestConfirmations',
        internalType: 'uint16',
      },
      { type: 'uint32', name: 'maxGasLimit', internalType: 'uint32' },
      {
        type: 'uint32',
        name: 'gasAfterPaymentCalculation',
        internalType: 'uint32',
      },
      {
        type: 'tuple',
        name: 'feeConfig',
        internalType: 'struct ErinaceusVRF.FeeConfig',
        components: [
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier1',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier2',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier3',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier4',
            internalType: 'uint32',
          },
          {
            type: 'uint32',
            name: 'fulfillmentFlatFeeWFTNPPMTier5',
            internalType: 'uint32',
          },
          { type: 'uint24', name: 'reqsForTier2', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier3', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier4', internalType: 'uint24' },
          { type: 'uint24', name: 'reqsForTier5', internalType: 'uint24' },
        ],
      },
    ],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'setHushHub',
    inputs: [{ type: 'address', name: '_hashHub', internalType: 'address' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'setRewardForHashHub',
    inputs: [{ type: 'uint256', name: '_amount', internalType: 'uint256' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'setTeam',
    inputs: [
      { type: 'address', name: '_teamAddress', internalType: 'address' },
      { type: 'uint256', name: '_feePercentage', internalType: 'uint256' },
    ],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'address' }],
    name: 'team',
    inputs: [],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'transferOwnership',
    inputs: [{ type: 'address', name: 'newOwner', internalType: 'address' }],
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawForTeam',
    inputs: [{ type: 'uint256', name: 'amount', internalType: 'uint256' }],
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'withdrawableForTeam',
    inputs: [],
  },
]
