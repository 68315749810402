export const APPS = {
  WEBSITE: 'website',
  VRF: 'vrf',
  PRICE_FEED: 'price-feed',
}

export const ENV = {
  PROD: 'prod',
  DEV: 'dev',
}

const monorepoAppUrlMapping = {
  [APPS.WEBSITE]: {
    [ENV.PROD]: 'https://erinaceus.io',
    [ENV.DEV]: 'http://localhost:5170',
  },
  [APPS.VRF]: {
    [ENV.PROD]: 'https://vrf.erinaceus.io',
    [ENV.DEV]: 'http://localhost:5171',
  },
  [APPS.PRICE_FEED]: {
    [ENV.PROD]: 'https://pricefeed.erinaceus.io',
    [ENV.DEV]: 'http://localhost:5172',
  },
}

// TODO: Maybe move origins to env for flexibility
// TODO: Add DATA_FEED in future
export const getMonorepoAppUrl = ({ app = APPS.WEBSITE }) => {
  const env = import.meta.env.PROD ? ENV.PROD : ENV.DEV

  return monorepoAppUrlMapping[app][env]
}

export const trimAddress = (address = '', symbols = 3) => {
  if (symbols <= 0) return ''
  if (symbols * 2 >= address.length) return address

  const left = address.slice(0, symbols)
  const right = address.slice(-symbols)

  return `${left}...${right}`
}

export const openWindow = (url = '', newTab = true) => {
  if (typeof window === 'undefined') return

  window.open(url, newTab ? '_blank' : '_self', 'noopener,noreferrer')
}
