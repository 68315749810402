import { bahamut as defaultBahamut } from 'wagmi/chains'

export const ocean = {
  id: 4058,
  network: 'ocean',
  name: 'Ocean',
  nativeCurrency: defaultBahamut.nativeCurrency,
  rpcUrls: {
    default: {
      http: ['https://rpc1.ocean.bahamut.io'],
      webSocket: ['wss://ws1.ocean.bahamut.io'],
    },
    public: {
      http: ['https://rpc1.ocean.bahamut.io'],
      webSocket: ['wss://ws1.ocean.bahamut.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Ftnscan',
      url: 'https://ocean.ftnscan.com',
      apiUrl: 'https://ocean.ftnscan.com/api',
    },
  },
  contracts: {
    vrf: {
      address: import.meta.env.VITE_VRF_ADDRESS_OCEAN,
      keyhash: import.meta.env.VITE_KEY_HASH_OCEAN,
    },
  },
}

export const bahamut = {
  ...defaultBahamut,
  rpcUrls: {
    default: {
      http: ['https://rpc2.bahamut.io', 'https://bahamut.publicnode.com'],
    },
    public: {
      http: ['https://rpc2.bahamut.io', 'https://bahamut.publicnode.com'],
    },
  },
  contracts: {
    vrf: {
      address: import.meta.env.VITE_VRF_ADDRESS_BAHAMUT,
      keyhash: import.meta.env.VITE_KEY_HASH_BAHAMUT,
    },
  },
}

export const supportedChains = [bahamut, ocean]

export const supportedChainsIdMapping = {
  [bahamut.network]: bahamut.id,
  [ocean.network]: ocean.id,
}

export const supportedChainsMapping = {
  [bahamut.id]: bahamut,
  [ocean.id]: ocean,
}
