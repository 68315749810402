import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link as ChakraLink,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'

const docsNavigationItems = [
  {
    label: 'VRF',
    href: 'vrf/overview',
    children: [
      { label: 'Overview', href: 'vrf/overview' },
      { label: 'Subscription', href: 'vrf/subscription' },
      { label: 'Direct Funding', href: 'vrf/direct-funding' },
    ],
  },
  {
    label: 'Price-Feed',
    href: 'price-feed/overview',
    children: [
      { label: 'Overview', href: 'price-feed/overview' },
      { label: 'Getting started', href: 'price-feed/getting-started' },
      {
        label: 'Developer Responsibilities',
        href: 'price-feed/developer-responsibilities',
      },
      {
        label: 'Contract Addresses',
        href: 'price-feed/contract-addresses',
      },
      {
        label: 'Using Price Feeds',
        href: 'price-feed/using-price-feeds',
      },
    ],
  },
]

export const DocsNavigation = () => {
  const { pathname } = useLocation()
  const isActive = useCallback((href) => pathname.includes(href), [pathname])

  const defaultIndex = docsNavigationItems.reduce(
    (index, item, currentIndex) => {
      if (pathname.includes(item.label.toLowerCase().replace(' ', '-'))) {
        return [currentIndex]
      }
      return index
    },
    [0],
  )

  return (
    <Accordion w={180} defaultIndex={defaultIndex}>
      {docsNavigationItems?.map(({ label, href, children }, index) => (
        <AccordionItem border={'none'} key={index}>
          <AccordionButton
            as={Link}
            to={href}
            fontSize={16}
            borderRadius={'6px'}
            py={'6px'}
            _expanded={{
              bgColor: 'primary.50',
              fontWeight: 400,
              color: 'primary.500',
            }}
          >
            <Text as="span">{label}</Text>
          </AccordionButton>

          {children?.length && (
            <AccordionPanel as={Flex} flexDirection={'column'} pt={0} pb={4}>
              {children?.map(({ label, href }, index) => (
                <ChakraLink
                  as={Link}
                  to={href}
                  key={index}
                  fontSize={14}
                  color={isActive(href) ? 'primary.500' : 'gray.400'}
                  fontWeight={isActive(href) ? 400 : 300}
                  flexDirection={'row'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  py={'3.5px'}
                >
                  <Divider
                    opacity={1}
                    borderColor={isActive(href) ? 'primary.500' : 'gray.400'}
                    h={'16px'}
                    orientation="vertical"
                  />

                  <Text>{label}</Text>
                </ChakraLink>
              ))}
            </AccordionPanel>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  )
}
