import { DocsContainer, DocsNavigation } from '@/components/organisms'
import { Layout } from '@/components/templates'
import { Box, HStack } from '@chakra-ui/react'
import { MainContainer } from '@erinaceus/ui'
import { Outlet } from 'react-router-dom'

export const Docs = () => {
  return (
    <Layout>
      <Box bgColor={'gray.50'} flex={1}>
        <MainContainer pt={'42px'}>
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={'stretch'}
            spacing={'20px'}
            height={'100%'}
          >
            <DocsNavigation />

            <DocsContainer flex={1}>
              <Outlet />
            </DocsContainer>
          </HStack>
        </MainContainer>
      </Box>
    </Layout>
  )
}
