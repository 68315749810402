import { CopyIcon } from '@chakra-ui/icons'
import { Box, Stack, Tooltip, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'

export const Copiable = ({ children, copiableText, ...props }) => {
  const toast = useToast()

  const handleCopy = () => {
    copy(copiableText)
    toast({
      title: `Copied`,
      position: 'bottom',
      duration: 1000,
      isClosable: true,
      status: 'success',
    })
  }

  return (
    <Stack direction={'row'} alignItems={'center'} {...props}>
      <Box>{children}</Box>
      <Tooltip label="Copy to clipboard">
        <CopyIcon cursor={'pointer'} onClick={handleCopy} />
      </Tooltip>
    </Stack>
  )
}
