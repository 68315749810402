import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react'

export const SubNav = ({
  LinkElement,
  newTab = false,
  label,
  href,
  subLabel,
}) => {
  const linkElementParams = {
    as: LinkElement || 'a',
    target: newTab ? '_blank' : '_self',
    [LinkElement ? 'to' : 'href']: href,
  }

  return (
    <Box
      {...linkElementParams}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{
        bg: 'primary.50',
      }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text transition={'all .3s ease'} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'primary.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}
