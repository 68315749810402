import { formatBalance } from '@/utils'
import { HStack, Text, Tooltip } from '@chakra-ui/react'

// TODO: move to shared ui
export const Token = ({ amount, decimal = 3, symbol, toFtn = true }) => {
  return (
    <Tooltip placement={'top-start'} label={formatBalance(amount, 20, toFtn)}>
      <HStack spacing={1}>
        <Text>{formatBalance(amount, decimal, toFtn)}</Text>
        <Text color={'gray.500'} fontSize={'sm'}>
          {symbol}
        </Text>
      </HStack>
    </Tooltip>
  )
}
