import { supportedChains, supportedChainsIdMapping } from '@/utils'
import { createConfig, http } from 'wagmi'

export const config = createConfig({
  chains: supportedChains,
  multiInjectedProviderDiscovery: false,
  transports: {
    [supportedChainsIdMapping.bahamut]: http(),
  },
})
