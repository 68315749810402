import { supportedChainsMapping } from '@/utils'
import { useAccount, useChainId } from 'wagmi'

export const useWeb3 = () => {
  const { isConnected, chainId: accountChainId } = useAccount()
  const chainId = useChainId()
  const isCorrectChain =
    isConnected && (chainId === accountChainId || accountChainId == 0)

  return {
    isCorrectChain, // TODO: remove later
    isSupportedNetwork: isConnected
      ? chainId === accountChainId || accountChainId == 0
      : true,
    chain: supportedChainsMapping[chainId],
  }
}
