import { DocsBody } from '@/components/organisms'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import DocsText from '../../DocsText'

export const DocsPriceFeedDeveloperResponsibilities = () => {
  const docs = [
    {
      label: 'Market Integrity and Application Code Risks',
      children: [
        {
          text: 'Erinaceus Price Feeds provide a secure, reliable, and decentralized source of real-world data on the blockchain. However, the value of assets using Erinaceus Price Feeds is influenced by market conditions beyond the control of Erinaceus node operators. Therefore, developers are responsible for ensuring that Erinaceus Price Feeds perform as expected in their applications.',
        },
        {
          text: 'When integrating Erinaceus Price Feeds, developers must be aware of the inherent risks associated with market integrity and application code. ',
        },
        {
          Component: (
            <UnorderedList spacing={2}>
              <ListItem>
                <DocsText>
                  <b>Market Integrity Risks</b> involve external market
                  conditions that can impact price behavior and data quality in
                  unpredictable ways. Developers need to actively monitor and
                  manage these risks to maintain data accuracy.
                </DocsText>
              </ListItem>
              <ListItem>
                <DocsText>
                  <b>Application Code Risks</b> relate to the quality,
                  reliability, and dependencies of the code within an
                  application. Developers must carefully monitor and address any
                  issues in their code to prevent potential failures.
                </DocsText>
              </ListItem>
            </UnorderedList>
          ),
        },
        {
          title: 'Developer Responsibilities',
          text: 'Developers are accountable for ensuring the security and user experience of their applications, as well as for securely managing all interactions between their applications and third-party services.',
        },
        {
          text: "Specifically, developers incorporating Erinaceus Price Feeds into their code and applications must take responsibility for their application's market integrity and the potential risks associated with their code, which may lead to unexpected pricing data behavior. These responsibilities and risks are detailed below.",
        },
      ],
    },
    {
      label: 'Market Integrity Risks',
      children: [
        {
          text: 'Market conditions can impact asset pricing behavior in ways that are beyond the control of Price Feed providers.',
        },
        {
          text: 'Factors affecting market integrity include manipulation tactics such as spoofing, ramping, bear raids, cross-market manipulation, wash trading, and front-running. All assets are subject to market risk, but low liquidity assets are especially vulnerable to such manipulation. Developers are responsible for addressing these risk factors when integrating Price Feeds into their applications. They should fully understand the market risks associated with the assets their application will support and ensure their end users are aware of these risks. Ultimately, developers must be proactive in identifying and mitigating market integrity risks to ensure the reliability and trustworthiness of their applications using these Price Feeds.',
        },
      ],
    },
    {
      label: 'Application Code Risks',
      children: [
        {
          text: 'Developers integrating Erinaceus Price Feeds must take full responsibility for implementing essential risk mitigation measures. These measures include data quality checks, circuit breakers, and appropriate contingency logic tailored to their specific use cases.',
        },
        {
          Component: (
            <DocsText>
              <b>Code Quality and Reliability:</b> Developers need to ensure
              that any code using Erinaceus Price Feeds meets the necessary
              quality and reliability standards for their intended use case and
              application.
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              <b>Code and Application Audits:</b> Before deploying their code
              and applications to production, developers must conduct thorough
              audits. They are responsible for verifying that the audits meet
              the required standards for their application.
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              <b>Code Dependencies and Imports:</b> Developers must ensure the
              quality, reliability, and security of any dependencies or imported
              packages used with Erinaceus Price Feeds. This involves
              comprehensive review and auditing of these dependencies and
              packages.
            </DocsText>
          ),
        },
      ],
    },
  ]

  return (
    <DocsBody
      title={'Price Feed'}
      subtitle={'Developer Responsibilities'}
      docs={docs}
    />
  )
}
