import {
  DocsBody,
  DocsVrfSubscriptionBilling,
  DocsVrfSubscriptionUsingFlow,
} from '@/components/organisms'

export const DocsVrfSubscription = () => {
  const docs = [
    {
      label: 'Main',
      children: [
        {
          text: 'To subscribe, a user must create a subscription account and fund its balance with FTN tokens. Erinaceus calculates the transaction costs after requests are fulfilled, and credits will be accordingly deducted from a user’s subscription balance. One subscription account can be connected to multiple smart contracts. Subscriptions are typically better for those with regular VRF requests, those who want to reduce gas overhead and have more control over gas costs, those who want more random values per request, or those who don’t want to precisely estimate the costs for each request.',
        },
      ],
    },
    {
      label: 'Billing',
      Component: <DocsVrfSubscriptionBilling />,
    },
    {
      label: 'Using Flow',
      Component: <DocsVrfSubscriptionUsingFlow />,
    },
  ]

  return <DocsBody title={'VRF'} subtitle={'Subscription'} docs={docs} />
}
