import {
  DocsBody,
  DocsVrfDirectFundingBilling,
  DocsVrfDirectFundingUsingFlow,
} from '@/components/organisms'

export const DocsVrfDirectFunding = () => {
  const docs = [
    {
      label: 'Main',
      children: [
        {
          text: 'Direct funding involves a smart contract directly paying with FTN tokens as they request a VRF. The contract must be funded sufficiently to pay for requests, or they will not be completed. This is generally better for entities with fewer requests that are okay with spending higher amounts of gas. In addition, entities using direct funding need to be ready to measure transaction costs carefully for each request to ensure the consuming smart contract has sufficient funds to pay for it.',
        },
      ],
    },
    {
      label: 'Billing',
      Component: <DocsVrfDirectFundingBilling />,
    },
    {
      label: 'Using Flow',
      Component: <DocsVrfDirectFundingUsingFlow />,
    },
  ]

  return <DocsBody title={'VRF'} subtitle={'Direct Funding'} docs={docs} />
}
