import { aggregatorProxyABI } from '@/abi/aggregatorProxyABI'
import { config } from '@/providers/WagmiProvider/config'
import { bahamut } from '@/utils'
import formatWithDecimals from '@/utils/formatWithDecimals'
import { readContract } from '@wagmi/core'
import { format, secondsToMilliseconds } from 'date-fns'

export const getPriceFeeds = async () => {
  const PRICE_FEED_CONTRACTS = import.meta.env.VITE_PRICE_FEED_CONTRACTS?.split(
    ' ',
  )

  const priceFeedsPromises = PRICE_FEED_CONTRACTS.map(
    async (priceFeedContract, idx) => {
      const metadataPromise = getLatestRoundMetadata(priceFeedContract)
      const priceFeedNamePromise = getPriceFeedName(priceFeedContract)

      const [{ answer }, { key, pair, convertedKey, symbol }] =
        await Promise.all([metadataPromise, priceFeedNamePromise])

      return {
        latestAnswer: answer,
        key,
        pair,
        convertedKey,
        symbol,
        contract: priceFeedContract,
        id: idx,
        chainId: bahamut.id,
      }
    },
  )

  const priceFeedsResults = await Promise.all(priceFeedsPromises)

  return priceFeedsResults
}

export const getDecimals = async (contractAddress) => {
  const data = await readContract(config, {
    abi: aggregatorProxyABI,
    address: contractAddress,
    functionName: 'decimals',
  })

  return data
}

export const getLatestRoundMetadata = async (contractAddress) => {
  try {
    const { answer, updatedAt } = await getLatestRoundData(contractAddress)

    const decimals = await getDecimals(contractAddress)

    const updatedAtInMilliseconds = updatedAt
      ? secondsToMilliseconds(Number(updatedAt))
      : 0

    return {
      answer: answer ? formatWithDecimals(answer, decimals) : 'N/A',
      updatedAt: updatedAt
        ? format(new Date(updatedAtInMilliseconds), 'MMM dd yyyy HH:mm:ss')
        : 'N/A',
    }
  } catch (err) {
    console.error(err.message)
  }
}

export const getLatestRoundAnswer = async (contractAddress) => {
  const { answer } = await getLatestRoundData(contractAddress)

  return answer ? Number(answer) / 1000000 : 'N/A'
}

export const getLatestRoundData = async (contractAddress) => {
  const data = await readContract(config, {
    abi: aggregatorProxyABI,
    address: contractAddress,
    functionName: 'latestRoundData',
  })

  const roundId = data[0]
  const answer = data[1]
  const startedAt = data[2]
  const updatedAt = data[3]
  const answeredInRound = data[4]

  return {
    answer,
    roundId,
    startedAt,
    updatedAt,
    answeredInRound,
  }
}

export const getPriceFeedName = async (contractAddress) => {
  const inputString = await getDescription(contractAddress)

  // Extracting "FTN/USD"
  const pair = inputString.split(' ').pop() // This splits the string by spaces and takes the last part

  // Extracting "FTN"
  const key = pair.split('/')[0] // This splits the pair by '/' and takes the first part
  const convertedKey = pair.split('/')[1] // This replaces all underscores with spaces

  return {
    key,
    pair,
    convertedKey,
    symbol: convertedKey.toLowerCase() === 'usd' ? '$' : 'Ξ',
  }
}

export const getDescription = async (contractAddress) => {
  const data = await readContract(config, {
    abi: aggregatorProxyABI,
    address: contractAddress,
    functionName: 'description',
  })

  return data
}
