import { DocsBody } from '@/components/organisms'
import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import DocsText from '../../DocsText'
import { CodeBlock } from 'react-code-blocks'
const code = `
  // SPDX-License-Identifier: MIT
  pragma solidity ^0.8.7;
  import {AggregatorV3Interface} from "./interfaces/AggregatorV3Interface.sol";
  /**
   * THIS IS AN EXAMPLE CONTRACT THAT USES HARDCODED
   * VALUES FOR CLARITY.
   * THIS IS AN EXAMPLE CONTRACT THAT USES UN-AUDITED CODE.
   * DO NOT USE THIS CODE IN PRODUCTION.
   */
   contract DataConsumerV3 {
      AggregatorV3Interface internal dataFeed;
      /**
       * Network: Ocean
       * Aggregator: FTN/USD
       * Address: 0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
       */
      constructor() {
          dataFeed = AggregatorV3Interface(
              0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
          );
      }
      /**
       * Returns the latest answer.
       */
      function getErinaceusDataFeedLatestAnswer() public view returns (int) {
          // prettier-ignore
          (
              /* uint80 roundID */,
              int answer,
              /*uint startedAt*/,
              /*uint timeStamp*/,
              /*uint80 answeredInRound*/
          ) = dataFeed.latestRoundData();
          return answer;
      }
  }
`

const getRoundDataCode = `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.7;
import {AggregatorV3Interface} from "./interfaces/AggregatorV3Interface.sol";
/**
 * THIS IS AN EXAMPLE CONTRACT THAT USES HARDCODED
 * VALUES FOR CLARITY.
 * THIS IS AN EXAMPLE CONTRACT THAT USES UN-AUDITED CODE.
 * DO NOT USE THIS CODE IN PRODUCTION.
 */
 contract DataConsumerV3 {
    AggregatorV3Interface internal dataFeed;
    /**
     * Network: Ocean
     * Aggregator: FTN/USD
     * Address: 0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
     */
    constructor() {
        dataFeed = AggregatorV3Interface(
            0x96bf8f417e3aEe062bacC847eFd612DD4ec479ce
        );
    }	/**
     * Returns the latest answer.
     */
    function getErinaceusDataFeedLatestAnswer() public view returns (int) {
        // prettier-ignore
        (
            /* uint80 roundID */,
            int answer,
            /*uint startedAt*/,
            /*uint timeStamp*/,
            /*uint80 answeredInRound*/
        ) = dataFeed.latestRoundData();
        return answer;
    }
}`

export const DocsPriceFeedUsingPriceFeeds = () => {
  const docs = [
    {
      label: 'Reading Price Feeds onchain',
      children: [
        {
          text: 'These code examples demonstrate how to deploy a consumer contract onchain that reads a data feed and stores the value.',
        },
        {
          Component: (
            <CodeBlock
              as={Box}
              w={500}
              text={code}
              language={'solidity'}
              showLineNumbers={true}
              theme={'dracula'}
            />
          ),
        },
      ],
    },
    {
      label: 'Getting Historical Data',
      children: [
        {
          text: 'Consumer contracts use the proxy contract to access the underlying aggregator contract. This setup allows the aggregator to be upgraded without affecting the consumer contracts. It also means that historical data can be stored in different aggregators.',
        },
        {
          text: 'To get historical data, follow these steps shown in the sequence diagram:',
          // Diagram
        },
        {
          Component: (
            <DocsText>
              call the <b>getRoundData</b> function and provide the{' '}
              <b>roundId</b> as a parameter.
              <br />
              Remember, <b>roundIds</b> have different meanings in proxy
              contracts and aggregator contracts.
            </DocsText>
          ),
        },
        {
          title: 'roundId in Aggregator (aggregatorRoundId)',
        },
        {
          Component: (
            <DocsText mt={'-24px'}>
              Oracles provide periodic data updates to the aggregators. Price
              Feeds are updated in rounds. Rounds are identified by their{' '}
              <b>roundId</b>, which increases with each new round. This increase
              may not be monotonic. Knowing the <b>roundId</b> of a previous
              round allows contracts to consume historical data. The examples in
              this document name the aggregator <b>roundId</b> as{' '}
              <b>aggregatorRoundId</b> to differentiate it from the proxy{' '}
              <b>roundId</b>.
            </DocsText>
          ),
        },
        {
          title: 'roundId in proxy',
        },
        {
          Component: (
            <Box mt={'-24px'}>
              <DocsText mb={2}>
                Because a proxy has references to current and all previous
                underlying aggregators, it needs a way to fetch data from the
                correct aggregator. The <b>roundId</b> is computed in the proxy
                contract as shown in the following example:
              </DocsText>

              <CodeBlock
                as={Box}
                text={`return uint80(uint256(_phase) << PHASE_OFFSET |_originalId);`}
                language={'solidity'}
                showLineNumbers={false}
                theme={'dracula'}
              />

              <Box mt={2}>
                Where:
                <UnorderedList>
                  <ListItem>
                    <DocsText>
                      <b>_phase</b> is incremented each time the underlying
                      aggregator implementation is updated. It is used as a key
                      to find the aggregator address.
                    </DocsText>
                  </ListItem>
                  <ListItem>
                    <DocsText>
                      <b>_originalId</b> is the aggregator roundId. The ID
                      starts at 1.
                    </DocsText>
                  </ListItem>
                </UnorderedList>
                From the above formula, you can think of it as returning a large
                number containing the <b>phase</b> and the aggregator{' '}
                <b>roundId</b>.
              </Box>
            </Box>
          ),
        },
        {
          title: 'getRoundData return values',
        },
        {
          Component: (
            <Box mt={'-24px'}>
              <DocsText>
                The <b>getRoundData</b> function returns the following values:
              </DocsText>
              <UnorderedList>
                <ListItem>
                  <b>roundId:</b> The round in which the answer was updated
                </ListItem>
                <ListItem>
                  <b>answer:</b> The answer reflects the data recorded for the
                  specified round
                </ListItem>
                <ListItem>
                  <b>answeredInRound:</b> Deprecated - Previously used when
                  answers could take multiple rounds to be computed
                </ListItem>
                <ListItem>
                  <b>startedAt:</b> The timestamp when the round started
                </ListItem>
                <ListItem>
                  <b>updatedAt:</b> The timestamp when the answer was computed
                </ListItem>
              </UnorderedList>
              <br />
              <CodeBlock
                as={Box}
                w={500}
                text={getRoundDataCode}
                language={'solidity'}
                showLineNumbers={true}
                theme={'dracula'}
              />
            </Box>
          ),
        },
      ],
    },
  ]

  return (
    <DocsBody title={'Price Feed'} subtitle={'Using Price Feeds'} docs={docs} />
  )
}
