import { navItems as NAV_ITEMS } from '@/constants'
import { Footer, FreshMainLogo, LayoutTemplate, Navbar } from '@erinaceus/ui'
import { Link, useNavigate } from 'react-router-dom'

export const Layout = ({ children, ...props }) => {
  const navigate = useNavigate()

  return (
    <LayoutTemplate {...props}>
      <Navbar
        logo={<FreshMainLogo onClick={() => navigate('/')} />}
        navItems={NAV_ITEMS}
        LinkElement={Link}
      />

      {children}

      <Footer />
    </LayoutTemplate>
  )
}
