import { AbsoluteCenter, Box, Text } from '@chakra-ui/react'
import { LayoutTemplate } from '.'

export const Page404 = () => {
  return (
    <LayoutTemplate>
      <Box textAlign="center">
        <AbsoluteCenter>
          <Text fontSize="5xl" fontWeight="bold" mt={4}>
            Error 404: Page Not Found
          </Text>
          <Text mt={2}>
            We&apos;re sorry, but the page you are looking for does not exist.
          </Text>
        </AbsoluteCenter>
      </Box>
    </LayoutTemplate>
  )
}
