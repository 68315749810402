import { Token } from '@/components/molecules'
import { useGetConfig, useGetFeeConfig } from '@/hooks'
import { toWeiFromGwei } from '@/utils'
import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import Big from 'big.js'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { formatEther, formatGwei } from 'viem'
import { useChainId, useGasPrice } from 'wagmi'

export const DirectCalculator = ({ ...props }) => {
  const [calcInitData, setCalcInitData] = useState({
    coordinatorGas: 90000,
    wrapperGas: 40000,
    averageVerificationGas: 92500,
    maxCallbackGasLimit: 0,
    gasPrice: 0,
    protocolFee: 0,
  })
  const [result, setResult] = useState(0)
  const chainId = useChainId()
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      callbackGas: 0,
      gasPrice: calcInitData.gasPrice,
    },
  })
  const { data: networkGasPrice } = useGasPrice({ chainId })
  const { data: vrfMaxCallbackGasLimit } = useGetConfig()
  const { data: vrfProtocolFee } = useGetFeeConfig()

  const onSubmit = ({ callbackGas, gasPrice, gasLane }) => {
    const result = calculate({ callbackGas, gasPrice, gasLane })
    result && setResult(result)
  }

  useEffect(() => {
    if (vrfProtocolFee) {
      const protocolFee = Number(formatEther(vrfProtocolFee * 1e12, 'wei'))

      setCalcInitData((prev) => ({ ...prev, protocolFee }))
    }
  }, [vrfProtocolFee])

  useEffect(() => {
    if (vrfMaxCallbackGasLimit) {
      setCalcInitData((prev) => ({
        ...prev,
        maxCallbackGasLimit: vrfMaxCallbackGasLimit,
      }))
    }
  }, [vrfMaxCallbackGasLimit])

  useEffect(() => {
    if (networkGasPrice) {
      const gasPriceInGwei = formatGwei(networkGasPrice, 'wei')

      setCalcInitData((prev) => ({
        ...prev,
        gasPrice: gasPriceInGwei,
      }))
      setValue('gasPrice', gasPriceInGwei)
    }
  }, [networkGasPrice, setValue])

  const onCallbackFocus = (e) => {
    if (!Number(e.target.value)) {
      e.target.select()
    }
  }

  const calculate = ({ callbackGas, gasPrice }) => {
    const finalCallbackGas = Math.min(
      callbackGas,
      calcInitData.maxCallbackGasLimit,
    )
    const gasSpent = Big(finalCallbackGas)
      .plus(calcInitData.coordinatorGas)
      .plus(calcInitData.wrapperGas)

    if (finalCallbackGas === calcInitData.maxCallbackGasLimit) {
      setValue('callbackGas', calcInitData.maxCallbackGasLimit)
    }

    const gasPriceInWei = toWeiFromGwei(Number(gasPrice)).toFixed(0)

    const gasCostInWei = Big(gasPriceInWei).times(gasSpent)
    const gasCostInEther = formatEther(gasCostInWei)

    const result = Big(gasCostInEther).plus(calcInitData.protocolFee).toString()

    return result
  }

  return (
    <Box
      as={'form'}
      p={['24px', '48px']}
      borderRadius={'24px'}
      bgColor={'white'}
      border={'1px solid'}
      borderColor={'gray.100'}
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <TableContainer
        overflow={'hidden'}
        whiteSpace={'none'}
        fontSize={['sm', 'md']}
      >
        <Table variant="simple">
          <TableCaption>Direct Request Calculation</TableCaption>
          <Thead>
            <Tr>
              <Th px={0} fontWeight={400} fontSize={'14px'}>
                Input
              </Th>
              <Th px={0} fontWeight={400} fontSize={'14px'}>
                Value
              </Th>
            </Tr>
          </Thead>
          <Tbody fontSize={'18px'} fontWeight={300}>
            <Tr>
              <Td pl={0}>
                Gas price (current is {calcInitData.gasPrice} gwei)
                <Tooltip label="The current gas price, which fluctuates depending on network conditions.">
                  <InfoIcon ml={2} />
                </Tooltip>
              </Td>
              <Td px={0}>
                <FormControl isInvalid={errors.gasPrice}>
                  <Input
                    fontSize={['sm', 'md']}
                    id="gasPrice"
                    {...register('gasPrice', {
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: 'Should be a number',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.gasPrice && errors.gasPrice.message}
                  </FormErrorMessage>
                </FormControl>
              </Td>
            </Tr>
            <Tr>
              <Td pl={0}>
                Callback gas (max. {calcInitData.maxCallbackGasLimit})
                <Tooltip label="The amount of gas used for the callback request that returns your requested random values.">
                  <InfoIcon ml={2} />
                </Tooltip>
              </Td>
              <Td px={0}>
                <Input
                  fontSize={['sm', 'md']}
                  onFocus={onCallbackFocus}
                  {...register('callbackGas', { valueAsNumber: true })}
                  type="number"
                />
              </Td>
            </Tr>

            <Tr>
              <Td pl={0}>
                Coordinator gas overhead
                <Tooltip label="The amount of gas used to verify randomness onchain.">
                  <InfoIcon ml={2} />
                </Tooltip>
              </Td>
              <Td px={0}>{calcInitData.coordinatorGas}</Td>
            </Tr>

            <Tr>
              <Td pl={0}>
                Wrapper overhead
                <Tooltip label="The amount of gas used by the VRF Wrapper contract.">
                  <InfoIcon ml={2} />
                </Tooltip>
              </Td>
              <Td px={0}>{calcInitData.wrapperGas}</Td>
            </Tr>

            <Tr>
              <Td pl={0}>Protocol Fee</Td>
              <Td px={0}>{calcInitData.protocolFee} FTN</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <VStack mt={4} spacing={2}>
        <Button variant={'solid'} type="submit">
          Calculate
        </Button>
        <VStack>
          <Flex gap={2}>
            <Text color={'primary.300'} fontWeight={400} as={'span'}>
              Estimated cost per request:
            </Text>
            <Token
              amount={result || 0}
              toFtn={false}
              symbol={'FTN'}
              decimal={8}
            />
          </Flex>
        </VStack>
      </VStack>
    </Box>
  )
}
