import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    width: 'auto',
    minHeight: '48px',
    fontWeight: '600',

    bg: 'primary.300',
    color: 'white',
    _hover: {
      bgColor: 'primary.400',
      _disabled: {
        backgroundColor: 'primary.400',
      },
    },
  },
  variants: {
    outline: {
      bg: 'transparent',
      border: '1.5px solid',
      borderColor: 'primary.500',
      color: 'primary.500',
      borderRadius: '7px',
      _hover: {
        bg: 'primary.50',
        borderColor: 'primary.600',
        color: 'primary.600',
        _disabled: {
          opacity: 1,
          bgColor: 'white',
          color: 'primary.100',
          borderColor: 'primary.100',
        },
      },
      _active: {
        bgColor: 'primary.50',
        borderColor: 'primary.600',
        color: 'primary.600',
      },
      _disabled: {
        opacity: 1,
        bgColor: 'white',
        color: 'primary.200',
        borderColor: 'primary.200',
      },
    },
    solid: {
      padding: '0 32px',
      width: 'auto',
      minHeight: '48px',
      bg: 'primary.500',
      color: 'white',
      fontWeight: '600',
      borderRadius: '7px', // TODO: get radius dynamically
      _hover: {
        bg: 'primary.600',
        _disabled: {
          bgColor: 'primary.100',
        },
      },
      _active: {
        bgColor: 'primary.600',
      },
      _disabled: {
        opacity: 1,
        bgColor: 'primary.200',
      },
    },
    soft: {
      bg: 'primary.50',
      color: 'primary.500',
      borderRadius: '7px',
      _active: {
        bgColor: 'primary.50',
        borderColor: 'primary.600',
        color: 'primary.600',
      },
      _disabled: {
        opacity: 1,
        color: 'primary.300',
        bgColor: 'primary.50',
      },
      _hover: {
        bgColor: 'primary.100',
        color: 'primary.600',
        _disabled: {
          opacity: 1,
          color: 'primary.200',
          bgColor: 'primary.0',
        },
      },
    },
    social: {
      borderRadius: '5px',
      bg: 'primary.500',
      color: 'white',
      fontWeight: '600',
      bgColor: 'primary.600',
    },
  },
})
