import { Box, Tooltip } from '@chakra-ui/react'
import { Address, Copiable } from '.'

export const AddressWithCopy = ({
  address,
  symbols = 3,
  fullAddress = false,
  linkStyles = {},
  tooltip = false,
}) => {
  return (
    <Copiable copiableText={address}>
      <Box variant={'solid'} color={'primary.500'} {...linkStyles}>
        <Tooltip isDisabled={!tooltip} label={address} placement={'top'}>
          <Address
            address={address}
            symbols={symbols}
            fullAddress={fullAddress}
          />
        </Tooltip>
      </Box>
    </Copiable>
  )
}
