import { DocBodySubtitle, DocBodyTitle } from '@/components/atoms'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Heading,
} from '@chakra-ui/react'
import DocsText from './DocsText'

export const DocsBody = ({ title, subtitle, docs, ...props }) => {
  return (
    <Box {...props}>
      {title && <DocBodyTitle mb={'24px'}>{title}</DocBodyTitle>}
      {subtitle && <DocBodySubtitle mb={'24px'}>{subtitle}</DocBodySubtitle>}

      <Accordion wordBreak={'break-word'} defaultIndex={[0]} allowToggle>
        {docs?.map(({ children, label, Component }, index) => {
          return (
            <AccordionItem
              py={['18px', '24px']}
              key={index}
              _first={{ borderTop: 'none' }}
            >
              <AccordionButton
                p={'24px'}
                h={65}
                borderRadius={'12px'}
                _expanded={{
                  bgColor: 'primary.600',
                  color: 'white',
                  borderBottomLeftRadius: '0',
                  borderBottomRightRadius: '0',
                }}
              >
                <Box
                  fontSize={['16px', '18px']}
                  fontWeight={400}
                  as="span"
                  flex="1"
                  textAlign="left"
                >
                  {label}
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel
                borderBottomLeftRadius={'12px'}
                borderBottomRightRadius={'12px'}
                bgColor={'tertiary.50'}
                py={['8px', '14px']}
                px={['18px', '24px']}
              >
                {Component && Component}

                {children?.map(
                  ({ title, subtitle, text, code, Component }, index) => {
                    return (
                      <Box py={['6px', '10px']} key={index}>
                        {Component && Component}

                        {code && (
                          <Center fontWeight={400} fontSize={'18px'}>
                            {code}
                          </Center>
                        )}

                        {title && (
                          <Heading
                            mb={'20px'}
                            fontSize={['22px', '24px']}
                            lineHeight={'26px'}
                            fontWeight={400}
                          >
                            {title}
                          </Heading>
                        )}

                        {subtitle && (
                          <Heading
                            lineHeight={'26px'}
                            fontSize={['16px', '18px']}
                            fontWeight={400}
                          >
                            {subtitle}
                          </Heading>
                        )}

                        {text && <DocsText>{text}</DocsText>}
                      </Box>
                    )
                  },
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </Box>
  )
}
