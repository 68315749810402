import { DocsBody } from '@/components/organisms'
import { ListItem, OrderedList, Text } from '@chakra-ui/react'
import DocsText from '../../DocsText'

export const DocsPriceFeedOverview = () => {
  const docs = [
    {
      label: 'Erinaceus Price Feeds',
      children: [
        {
          text: 'Erinaceus Price Feeds provide a fast and efficient way to connect your smart contracts to real-world data, such as asset prices. Designed to be fast, reliable, and secure, Erinaceus Price Feeds ensure your decentralized applications have access to accurate and timely information, enabling smarter and more efficient decision-making. With easy integration and solid performance, Erinaceus Price Feeds are the perfect solution for bringing practical data into your blockchain projects.',
        },
        {
          text: 'Smart contracts often need to act in real-time based on data such as asset prices, especially in the decentralized finance (DeFi) sector. This is where Erinaceus Price Feeds come into play. For instance, lending and borrowing platforms like Mutuari use Price Feeds to verify the total value of collateral, maintaining the integrity and reliability of their services. By integrating Erinaceus Price Feeds, these platforms can operate seamlessly, providing users with real-time, accurate data crucial for financial transactions and decisions.',
        },
      ],
    },
    {
      label: 'Components of a Price Feed',
      children: [
        {
          text: 'Price Feeds are a prime example of a decentralized oracle network, consisting of several key components:',
        },
        {
          Component: (
            <OrderedList>
              <ListItem>
                <b>Consumer:</b> The consumer is either an on-chain or off-chain
                application that utilizes Price Feeds. These applications depend
                on the data provided by the Price Feeds to function correctly,
                such as a DeFi platform determining asset prices or a supply
                chain system tracking product inventories.
              </ListItem>
              <ListItem>
                <b>Proxy Contract:</b> Proxy contracts are on-chain proxies that
                point to the aggregator for a specific Price Feed. These proxies
                allow the underlying aggregator to be upgraded without causing
                any service interruption to the consuming contracts. This
                ensures that the data flow remains continuous and reliable, even
                when improvements or updates are made to the aggregator.
              </ListItem>
              <ListItem>
                <b>Aggregator Contract:</b> The aggregator contract plays a
                crucial role by receiving periodic data updates from the oracle
                network. It stores this aggregated data on-chain, making it
                accessible for consumers. By retrieving and acting upon this
                data within the same transaction, consumers can ensure that
                their operations are based on the most up-to-date and accurate
                information available.
              </ListItem>
            </OrderedList>
          ),
        },
        {
          text: 'By incorporating these components, Erinaceus Price Feeds provide a solid and efficient solution for integrating real-world data into smart contracts, ensuring accuracy, reliability, and seamless upgrades.',
        },
      ],
    },
    {
      label: 'Reading proxy and aggregator configurations',
      children: [
        {
          Component: (
            <DocsText>
              Since the proxy and aggregator contracts are on-chain, you can
              view the current configuration by reading the variables using a
              blockchain explorer for your network. For example, you can check
              the FTN/USD proxy configuration using{' '}
              <Text
                fontWeight={600}
                textDecoration={'underline'}
                as={'a'}
                href={
                  'https://www.ftnscan.com/address/0x7Aa6e7B2301C0A16342be774f33a03F296a6325D/read-contract#address-tabs'
                }
              >
                FTNscan.
              </Text>
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              If you read the FTN/USD proxy configuration, you can query all of
              the functions and variables that are publicly accessible for that
              contract including the <b>aggregator</b> address,{' '}
              <b>latestRoundData()</b> function, <b>latestAnswer</b> variable,{' '}
              <b>owner</b> address, <b>latestTimestamp</b> variable, and several
              others. To see descriptions for the proxy contract variables and
              functions, see the source code for your specific Price Feed on{' '}
              <Text
                fontWeight={600}
                textDecoration={'underline'}
                as={'a'}
                href={'https://www.ftnscan.com'}
              >
                FTNscan.
              </Text>
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              The proxy contract points to an aggregator. This allows you to
              retrieve data through the proxy even if the aggregator is
              upgraded. If you view the <b>aggregator</b> address defined in the
              proxy configuration, you can see the aggregator and its
              configuration. For example, see the FTN/USD aggregator contract in
              FTNscan. This contract includes several variables and functions,
              including another <b>latestRoundData()</b>. To see descriptions
              for the aggregator variables and functions, see the source code on{' '}
              <Text
                fontWeight={600}
                textDecoration={'underline'}
                as={'a'}
                href={
                  'https://www.ftnscan.com/address/0x7Aa6e7B2301C0A16342be774f33a03F296a6325D/read-contract#address-tabs'
                }
              >
                FTNscan.
              </Text>
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              You can call the <b>latestRoundData()</b> function directly on the
              aggregator, but it is a best practice to use the proxy instead so
              that changes to the aggregator do not affect your application.
              Similar to the proxy contract, the aggregator contract has a{' '}
              <b>latestAnswer</b> variable, <b>owner</b> address,{' '}
              <b>latestTimestamp</b> variable, and several others.
            </DocsText>
          ),
        },
      ],
    },
    {
      label: 'Updates to proxy and aggregator contracts',
      children: [
        {
          text: 'Erinaceus Price Feeds are periodically updated to add new features and capabilities. These updates address external factors such as token migrations, protocol rebrands, extreme market events, and issues with data or node operations.',
        },
        {
          text: 'Updates may involve changes to the aggregator configuration or a complete replacement of the aggregator that the proxy uses. If you consume Price Feeds through the proxy, your applications can continue to operate seamlessly during these updates.',
        },
        {
          text: 'Both proxy and aggregator contracts have an owner address that has permission to modify variables and functions. For example, if you view the FTN/USD proxy contract on FTNscan, you can see the owner address.',
        },
      ],
    },
    {
      label: 'Monitoring Price Feeds',
      children: [
        {
          text: 'When building applications and protocols that rely on Price Feeds, it is crucial to include monitoring and safeguards to mitigate the negative impacts of extreme market events, possible malicious activity on third-party venues or contracts, potential delays, and outages.',
        },
        {
          text: 'Implement monitoring alerts based on deviations in the answers provided by Price Feeds. This will notify you of potential issues, allowing you to respond promptly.',
        },
        {
          title: 'Check the latest answer against reasonable limits',
        },
        {
          Component: (
            <DocsText mt={'-24px'}>
              The Price Feed aggregator includes both <b>_minSubmissionValue</b>{' '}
              and <b>_maxSubmissionValue</b> values. While these values are no
              longer used on most Price Feeds and do not prevent your
              application from accessing the latest answer, you must establish
              acceptable limits for your application.
            </DocsText>
          ),
        },
        {
          text: 'Configure your application to detect when the reported answer is approaching your predefined minimum and maximum limits, so it can alert you to potential market events. Additionally, set up your application to identify and respond to extreme price instability or prices that exceed your acceptable limits.',
        },
        {
          title: 'Check the timestamp of the latest answer',
        },
        {
          Component: (
            <DocsText mt={'-24px'}>
              Erinaceus Price Feeds do not provide streaming data. Instead, the
              aggregator updates its <b>latestAnswer</b> when certain criterias
              are met.
            </DocsText>
          ),
        },
        {
          Component: (
            <DocsText>
              Your application should track the <b>latestTimestamp</b> variable
              or use the <b>updatedAt</b> value from the{' '}
              <b>latestRoundData()</b> function to make sure that the latest
              answer is recent enough for your application to use it. If your
              application detects that the reported answer is not updated within
              the Drumbeat or within time limits that you determine are
              acceptable for your application, pause operation or switch to an
              alternate operation mode while identifying the cause of the delay.
            </DocsText>
          ),
        },
      ],
    },
  ]

  return <DocsBody title={'Price Feed'} subtitle={'Overview'} docs={docs} />
}
