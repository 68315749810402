import { Layout } from '@/components/templates'
import { Page404 as SharedPage404 } from '@erinaceus/ui'

export const Page404 = () => {
  return (
    <Layout>
      <SharedPage404 />
    </Layout>
  )
}
