import { theme as nativeTheme } from '@chakra-ui/react'
import { buttonTheme } from './button'
import { colors } from './colors'
import { containerTheme } from './container'
import { fonts } from './fonts'
import { inputTheme } from './input'
import { linkTheme } from './link'
import { styles } from './styles'

const { breakpoints: nativeBreakpoints } = nativeTheme

const breakpoints = {
  ...nativeBreakpoints,
  xl: '1320px',
}

export const theme = {
  styles,
  fonts,
  colors,
  breakpoints,
  components: {
    Container: containerTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Input: inputTheme,
  },
}
