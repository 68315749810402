import { Link as ChakraLink, Tooltip } from '@chakra-ui/react'
import { Address, Copiable } from '.'

export const AddressWithCopyAndLink = ({
  address,
  symbols = 3,
  fullAddress = false,
  linkStyles = {},
  to,
}) => {
  return (
    <Copiable copiableText={address}>
      <ChakraLink
        variant={'solid'}
        target={'_blank'}
        referrerPolicy={'no-referrer'}
        href={to}
        color={'primary.500'}
        _hover={{
          color: 'primary.600',
          textDecoration: 'underline',
        }}
        {...linkStyles}
      >
        <Tooltip label="View in explorer" placement={'top'}>
          <Address
            address={address}
            symbols={symbols}
            fullAddress={fullAddress}
          />
        </Tooltip>
      </ChakraLink>
    </Copiable>
  )
}
