import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const linkTheme = defineStyleConfig({
  baseStyle: {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
  },
})
