import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import '@fontsource-variable/lexend'
import { theme } from './theme'

export const SharedChakraProvider = ({ children, theme: nestedTheme = {} }) => {
  return (
    <ChakraProvider resetCSS={true} theme={extendTheme(theme, nestedTheme)}>
      {children}
    </ChakraProvider>
  )
}
