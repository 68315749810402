// import { bahamut, ocean, supportedChainsMapping } from '@/'
import { DirectCalculator } from '@/components/molecules'
import { Box, Text } from '@chakra-ui/react'

export const DocsVrfDirectFundingBilling = () => {
  return (
    <Box>
      <Text fontSize={['16px', '18px']} fontWeight={300}>
        Because the consuming contract directly pays for the request, the cost
        is calculated during the request and not during the callback when the
        randomness is fulfilled. Test your callback function to learn how to
        correctly estimate the callback gas limit.
      </Text>
      <Text
        mt={'20px'}
        textAlign={'center'}
        fontSize={['16px', '18px']}
        fontWeight={400}
      >
        (Gas price * (Verification gas + Callback gas limit + Wrapper gas
        overhead)) = total gas cost
      </Text>
      <Text textAlign={'center'} fontSize={['16px', '18px']} fontWeight={400}>
        (total gas cost + service fee) = total request cost
      </Text>

      <DirectCalculator mt={'20px'} />

      <Text fontSize={['16px', '18px']} mt={'20px'} fontWeight={300}>
        Make sure that your consuming contracts are funded with enough FTN
        tokens to cover the transaction costs. If not, your request will revert.
      </Text>
    </Box>
  )
}
