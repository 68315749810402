import { Container } from '@chakra-ui/react'

export const MainContainer = ({ children, ...props }) => {
  // maxW={'container.xl'} // TODO: make .xl as default to 82.5em
  return (
    <Container maxW={'82.5em'} py={24} {...props}>
      {children}
    </Container>
  )
}
