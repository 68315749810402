import { colors } from './colors'

export const styles = {
  global: {
    'html, body, #root': {
      height: '100%',
    },
    '::-webkit-scrollbar': {
      w: '8px',
      h: '8px',
      bgColor: colors.primary[50],
    },
    '::-webkit-scrollbar-thumb': {
      w: '8px',
      bgColor: colors.primary[500],
      borderRadius: '50px',
    },
  },
}
