import { Divider, Flex, HStack, Stack } from '@chakra-ui/react'
import { MainContainer, NavItems } from '.'

export const Navbar = ({
  logo,
  navItems = [],
  LinkElement,
  children,
  ...rest
}) => {
  return (
    <Flex
      alignItems={'center'}
      h={'80px'}
      as="nav"
      role="navigation"
      py={4}
      {...rest}
    >
      <MainContainer py={0}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <HStack>
            {logo}

            <Divider
              ml={[0, 4, 6]}
              mr={[0, 2, 4]}
              h={'32px'}
              orientation="vertical"
            />
            <NavItems
              LinkElement={LinkElement}
              navItems={navItems}
              flexDir={'row'}
              alignItems={'center'}
              // ml={[0, 4]}
            />
          </HStack>

          {children}
        </Stack>
      </MainContainer>
    </Flex>
  )
}
