import ChakraProvider from './ChakraProvider'
import QueryClientProvider from './QueryClientProvider'
import RouterProvider from './RouterProvider'
import WagmiProvider from './WagmiProvider'

export const Providers = () => {
  return (
    <ChakraProvider>
      <WagmiProvider>
        <QueryClientProvider>
          <RouterProvider />
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  )
}
