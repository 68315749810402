import { BigDecimal } from '@/utils/big'
import { formatEther } from 'viem'

export const formatBalance = (
  balance = 0,
  maxDecimalDigits = 2,
  toFtn = true,
) => {
  const safeBalance = BigDecimal(balance)
  const balanceString = toFtn
    ? formatEther(safeBalance)
    : safeBalance.toString()

  if (maxDecimalDigits > 0 && balanceString.includes('.')) {
    const [integerPart, decimalPart] = balanceString.split('.')
    const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString()
    return formattedIntegerPart + '.' + decimalPart.slice(0, maxDecimalDigits)
  } else {
    return parseInt(balanceString, 10).toLocaleString()
  }
}
