import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { ChecksIcon, MainContainer } from '@erinaceus/ui'
import { APPS, getMonorepoAppUrl } from '@erinaceus/utils'
import { Link } from 'react-router-dom'

export const Intro = ({ ...props }) => {
  const vrf = getMonorepoAppUrl({ app: APPS.VRF })

  return (
    <Box {...props}>
      <MainContainer>
        <Flex flexDirection={['column-reverse', 'row']} alignItems={'center'}>
          <Flex
            gap={'32px'}
            flexDirection={'column'}
            maxW={{ base: 'fill', lg: 560, xl: 760 }}
          >
            <Heading
              lineHeight={['56px', '60px', '80px']}
              fontWeight={600}
              fontSize={['48px', '56px', '72px']}
              color={'gray.900'}
            >
              Connecting Realities to Bahamut
            </Heading>

            <Text
              lineHeight={'28px'}
              fontSize={['18px', '20px']}
              color={'gray.900'}
              fontWeight={300}
            >
              Erinaceus is a transparent oracle protocol for your smart contract
              to easily get any data whenever you need it.
            </Text>

            <Flex gap={2}>
              <Flex
                flex={0.5}
                gap={'8px'}
                alignItems={'center'}
                color={'success.500'}
              >
                <ChecksIcon w={6} h={6} /> Market and Data Feeds
              </Flex>
              <Flex
                flex={0.5}
                gap={'8px'}
                alignItems={'center'}
                color={'success.500'}
              >
                <ChecksIcon w={6} h={6} /> VRF
              </Flex>
            </Flex>

            <Button
              alignSelf={'flex-start'}
              variant={'solid'}
              as={Link}
              to={vrf}
            >
              Checkout VRF
            </Button>
          </Flex>

          <Box
            alignSelf={'flex-end'}
            position={'relative'}
            display={['none', 'none', 'block']}
            overflow={'hidden'}
            mr={'-50px'}
          >
            <Image
              maxW={'575px'}
              w={'auto'}
              h={'auto'}
              src={'images/intro.png'}
            />
          </Box>
        </Flex>
      </MainContainer>
    </Box>
  )
}
