import { Box, Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { FreshMainLogo } from './FreshMainLogo'
import { MainContainer } from './MainContainer'
import { FacebookSocial, LinkedinSocial } from '../icons'
import { SocialIconButton } from './SocialIconButton'

export const Footer = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <MainContainer pt={'64px'} pb={'32px'}>
        <VStack alignItems={'stretch'}>
          <HStack justifyContent={'space-between'}>
            <FreshMainLogo />

            {children}
          </HStack>
          <Divider my={'42px'} />
          <HStack justifyContent={'space-between'}>
            <HStack>
              <SocialIconButton
                to={'https://www.facebook.com/erinaceusio'}
                icon={<FacebookSocial />}
              />
              <SocialIconButton
                to={'https://www.linkedin.com/company/erinaceus/'}
                icon={<LinkedinSocial />}
              />
            </HStack>

            <Text
              color={'primary.700'}
              fontSize={'16px'}
              fontWeight={300}
              lineHeight={'24px'}
            >
              © 2024 - Erinaceus.io
            </Text>
          </HStack>
        </VStack>
      </MainContainer>
    </Box>
  )
}
