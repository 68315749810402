import { AddIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
} from '@chakra-ui/react'
import { MainContainer } from '@erinaceus/ui'
import React from 'react'

const faq = [
  {
    title: 'What is Erinaceus?',
    text: 'Erinaceus is a transparent oracle protocol for your smart contract to easily get any data whenever you need it.',
  },
  {
    title: 'What is Erinaceus Verifiable Random Function (VRF)?',
    text: 'Erinaceus VRF introduces secure random number generation to the blockchain. Addressing the challenge of establishing an on-chain source of randomness that is publicly verifiable and tamper-proof, Erinaceus VRF is pivotal. Numerous smart contracts are enhancing their capabilities by integrating Erinaceus VRF, particularly in applications such as gaming dApps where demonstrating provably fair randomness, immune to external manipulation, is essential. Erinaceus VRF catalyzes the advancement of smart contracts across blockchain gaming, security, layer-two protocols, and a spectrum of other applications.',
  },
  {
    title: 'What is a blockchain oracle?',
    text: 'A blockchain oracle refers to any system that facilitates a smart contract by providing it with data from an off-chain source or linking it with an off-chain system. Oracles establish a bridge between blockchains and real-world data by interacting with external APIs and data feeds, enabling them to retrieve or transmit data to a smart contract.',
  },
  {
    title: 'Why do blockchains need oracles?',
    text: 'Oracles allow smart contracts to connect with external resources, expanding their capabilities beyond the confines of the blockchain. Without oracles, smart contracts are confined to on-chain data, greatly restricting their utility. Many transformative real-world applications of smart contracts necessitate access to off-chain data and processing, often requiring integration with traditional payment systems for contract settlement. For instance, a smart contract might need reliable weather information from a trusted provider like AccuWeather to initiate a transaction. It might also need to facilitate the release of escrowed fiat currency using established financial channels, like a bank account.',
  },
  {
    title: 'What is the oracle problem?',
    text: (
      <p>
        The oracle problem, also termed as the &quot;smart contract connectivity
        issue,&quot; arises from the blockchain&apos;s incapacity to directly
        access external off-chain systems due to its stringent security measures
        enforced by the consensus mechanism.
        <br />
        <br />
        Blockchain nodes execute standardized operations to achieve consensus on
        transactions, addressing the double-spend problem. However, they lack
        suitability in resolving subjective queries concerning real-world data,
        as access to information varies among nodes, complicating consensus
        formation and quality assurance. Consequently, blockchains prioritize
        transaction validity verification but restrict connectivity with
        off-chain systems to maintain network stability and deterministic
        transaction outcomes.
        <br />
        <br />
        To bridge the blockchain (on-chain) with external environments
        (off-chain), an independent infrastructure called an &quot;oracle&quot;
        is essential. Yet, reliance on a centralized oracle risks data
        manipulation, necessitating a decentralized oracle mechanism to uphold
        end-to-end smart contract execution security.
      </p>
    ),
  },
]

export const Faq = ({ ...props }) => {
  return (
    <Box {...props}>
      <MainContainer>
        <Flex
          gap={{ base: '50px', lg: '35px', xl: '100px' }}
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box flex={0.45}>
            <Heading
              lineHeight={'60px'}
              fontWeight={600}
              fontSize={{ base: '48px', xl: '56px' }}
            >
              Frequently asked questions
            </Heading>
          </Box>
          <Box flex={0.55}>
            <Accordion
              allowToggle
              p={{ base: '10px', sm: '20px' }}
              borderRadius={'20px'}
              bgColor={'white'}
              style={{
                boxShadow: '0px 12px 56px 0px rgba(6, 28, 61, 0.08)',
              }}
            >
              {faq.map(({ title, text }, index) => (
                <React.Fragment key={index}>
                  <AccordionItem border={'none'}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          p={{ base: '15px', sm: '20px' }}
                          fontSize={'18px'}
                          color={'gray.900'}
                          fontWeight={400}
                          borderRadius={'12px'}
                          _expanded={{
                            bgColor: 'primary.600',
                            color: 'white',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          gap={{ base: '10px', sm: '25px' }}
                        >
                          <Box as="span" flex="1" textAlign="left">
                            {title}
                          </Box>
                          <AddIcon
                            transition={'transform .15s'}
                            transform={isExpanded && 'rotate(45deg)'}
                            color={isExpanded && 'white'}
                            fontSize="15px"
                          />
                        </AccordionButton>

                        <AccordionPanel
                          p={{ base: '15px', sm: '20px' }}
                          borderBottomLeftRadius={'12px'}
                          borderBottomRightRadius={'12px'}
                          bgColor={'tertiary.50'}
                          fontSize={'16px'}
                          fontWeight={300}
                          lineHeight={'24px'}
                        >
                          {text}
                        </AccordionPanel>
                        <Divider
                          color={'gray.50'}
                          hidden={isExpanded || index === faq.length - 1}
                        />
                      </>
                    )}
                  </AccordionItem>
                </React.Fragment>
              ))}
            </Accordion>
          </Box>
        </Flex>
      </MainContainer>
    </Box>
  )
}
