import { bahamut, ocean, supportedChainsMapping } from '@/utils'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { AddressWithCopy, AddressWithCopyAndLink } from '@erinaceus/ui'

const configsMapping = [
  {
    title: 'Bahamut',
    configs: [
      {
        label: 'VRF Coordinator Contract Address',
        value: supportedChainsMapping[bahamut.id].contracts.vrf.address,
        isAddress: true,
        chainId: bahamut.id,
      },
      {
        label: 'keyhash',
        value: supportedChainsMapping[bahamut.id].contracts.vrf.keyhash,
        isCopiable: true,
      },
      {
        label: 'VRFConsumerBase.sol',
        value: 'VRFConsumerBase.sol(abstract contract)',
      },
      {
        label: 'VRFCoordinatorInterface.sol',
        value: 'VRFCoordinatorInterface.sol(interface contract)',
      },
    ],
  },
  {
    title: 'Ocean',
    configs: [
      {
        label: 'VRF Coordinator Contract Address',
        value: supportedChainsMapping[ocean.id].contracts.vrf.address,
        isAddress: true,
        chainId: ocean.id,
      },
      {
        label: 'keyhash',
        value: supportedChainsMapping[ocean.id].contracts.vrf.keyhash,
        isCopiable: true,
      },
      {
        label: 'VRFConsumerBase.sol',
        value: 'VRFConsumerBase.sol(abstract contract)',
      },
      {
        label: 'VRFCoordinatorInterface.sol',
        value: 'VRFCoordinatorInterface.sol(interface contract)',
      },
    ],
  },
]

export const DocsVrfOverviewConfigs = () => {
  const getExplorerUrl = (chainId) =>
    supportedChainsMapping[chainId]?.blockExplorers?.default?.url
  const getUrl = (address, chainId) =>
    `${getExplorerUrl(chainId)}/address/${address}`

  return (
    <VStack gap={'24px'} alignItems={'stretch'}>
      {configsMapping?.map(({ title, configs }, index) => {
        return (
          <Box bgColor={'tertiary.50'} key={index}>
            <Text fontSize={'20px'} fontWeight={400}>
              {title}
            </Text>

            <VStack pb={index === 0 && '32px'} alignItems={'stretch'}>
              {configs?.map(
                ({ label, value, isAddress, isCopiable, chainId }, index) => {
                  return (
                    <HStack
                      fontSize={'18px'}
                      fontWeight={300}
                      justifyContent={'space-between'}
                      alignItems={'flex-end'}
                      key={index}
                      gap={['10px', '20px', '60px', '80px']}
                    >
                      <Text
                        flex={1}
                        py={'24px'}
                        borderBottom={'1px solid'}
                        borderColor={'gray.100'}
                        wordBreak={'break-word'}
                        alignSelf={'normal'}
                      >
                        {label}
                      </Text>
                      <Box
                        flex={1}
                        py={'24px'}
                        borderBottom={'1px solid'}
                        borderColor={'gray.100'}
                        wordBreak={'break-word'}
                      >
                        {isAddress && (
                          <AddressWithCopyAndLink
                            address={value}
                            symbols={8}
                            to={getUrl(value, chainId)}
                          >
                            {value}
                          </AddressWithCopyAndLink>
                        )}

                        {isCopiable && (
                          <AddressWithCopy
                            tooltip={true}
                            address={value}
                            symbols={8}
                          >
                            {value}
                          </AddressWithCopy>
                        )}

                        {!isCopiable && !isAddress && value}
                      </Box>
                    </HStack>
                  )
                },
              )}
            </VStack>

            {index === 0 && (
              <Box h={'24px'} bg={'white'} transform={'scaleX(1.5)'} />
            )}
          </Box>
        )
      })}
    </VStack>
  )
}
