import { Flex } from '@chakra-ui/react'

export const LayoutTemplate = ({ children, ...props }) => {
  return (
    <Flex
      w={'full'}
      h={'100%'}
      direction={'column'}
      alignItems={'stretch'}
      {...props}
    >
      {children}
    </Flex>
  )
}
