import { Box, Button, HStack, Image, Text, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import { CodeBlock } from 'react-code-blocks'

const code = `pragma solidity ^0.8.6;

import "../vrf/VRFConsumerBaseV2.sol";
import "../vrf/ErinaceusVRF.sol";
import "hardhat/console.sol";

contract ConsumerMock is VRFConsumerBaseV2 {
    ErinaceusVRF public erinaceus;
    mapping(uint256 => uint256[]) public randomWord;
    uint256 public currentId;
    constructor(address erinaceusVRF)VRFConsumerBaseV2(erinaceusVRF){
        erinaceus = ErinaceusVRF(erinaceusVRF);
    }
    function getRandom(uint256 id) public view returns (uint256[] memory){
        return randomWord[id];
    }
    function generateRandomWords(
        bytes32 keyHash,
        uint64 subId,
        uint16 requestConfirmations,
        uint32 callbackGasLimit,
        uint32 numWords
    ) external {
            erinaceus.requestRandomWords(
            keyHash,
            subId,
            requestConfirmations,
            callbackGasLimit,
            numWords
            );
    }
    function fulfillRandomWords(uint256 requestId, uint256[] memory randomWords) internal virtual override {
        randomWord[currentId] = randomWords;
        currentId++;
    }
}
`

const ConsumerCode = ({ ...props }) => {
  const toast = useToast()

  const handleCopy = () => {
    copy(code)
    toast({
      title: `Copied`,
      position: 'bottom',
      duration: 1000,
      isClosable: true,
      status: 'success',
    })
  }

  return (
    <Box {...props}>
      <HStack
        mb={'24px'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <Text
          wordBreak={'break-word'}
          fontSize={['16px', '18px']}
          fontWeight={300}
        >
          contracts/Mocks/ConsumerMock.sol
        </Text>
        <Button onClick={() => handleCopy()} variant={'outline'}>
          Copy source code
        </Button>
      </HStack>

      <CodeBlock
        as={Box}
        w={500}
        text={code}
        language={'solidity'}
        showLineNumbers={true}
        theme={'dracula'}
      />
    </Box>
  )
}

export const DocsVrfSubscriptionUsingFlow = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <Image
          src="/images/subscription-flow.png"
          alt="VRF Subscription Flow"
        />
        <Text my={'20px'} fontSize={'18px'} fontWeight={300}>
          The consumer contract is a contract prepared by the consumer to
          receive the random numbers. It needs to inherit and override the
          fulfillRandomWords method of the abstract contract
          VRFConsumerBase.sol.
        </Text>
        <Text fontSize={'18px'} fontWeight={300}>
          A simple consumer contract as follows.
        </Text>
      </Box>

      <ConsumerCode mt={'40px'} />
    </Box>
  )
}
