import { Text } from '@chakra-ui/react'

export default function DocsText({ children, ...props }) {
  return (
    <Text
      textAlign={{ base: 'left', lg: 'justify' }}
      lineHeight={'26px'}
      fontSize={['16px', '18px']}
      fontWeight={300}
      {...props}
    >
      {children}
    </Text>
  )
}
