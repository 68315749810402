import { getPriceFeeds } from '@/actions'
import { DocsBody } from '@/components/organisms'
import { AddressWithCopyAndLink } from '@erinaceus/ui'
import {
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { supportedChainsMapping } from '@/utils'

export const DocsPriceFeedContractAddresses = () => {
  const { data: priceFeeds, isLoading } = useQuery({
    queryKey: ['price-feeds'],
    queryFn: () => getPriceFeeds(),
  })

  const getExplorerUrl = (chainId) => {
    return supportedChainsMapping[chainId]?.blockExplorers?.default?.url
  }

  const getUrl = ({ address, chainId }) => {
    return `${getExplorerUrl(chainId)}/address/${address}`
  }

  const docs = [
    {
      label: 'Mainnet Addresses',
      children: [
        {
          Component: (
            <>
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Pair</Th>
                        <Th>Address</Th>
                        <Th>Latest Answer</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {priceFeeds?.map((item) => (
                        <Tr key={item.contract}>
                          <Td>{item.pair}</Td>
                          <Td>
                            <AddressWithCopyAndLink
                              to={getUrl({
                                address: item.contract,
                                chainId: item.chainId,
                              })}
                              address={item.contract}
                              symbols={8}
                            />
                          </Td>
                          <Td>
                            {item.latestAnswer} {item.symbol}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </>
          ),
        },
      ],
    },
  ]

  return (
    <DocsBody
      title={'Price Feed'}
      subtitle={'Contract Addresses'}
      docs={docs}
    />
  )
}
